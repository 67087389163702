<template>
  <div class="my-chapter">
    <CommonHeaderNav :chapter="chapterHeaderData" :isShowBeginBTn="isShowBeginBTn" @openAdjustPlan="openAdjustPlan"
                     v-if="Object.keys(chapterHeaderData).length > 0" @beginStudyClick="beginStudyClick"
                     @handleOpenShare="handleOpenShare" @handleStatusSelect="handleStatusSelect"
                     @handleFlagSelect="handleFlagSelect"
                     @handleSearch="handleSearch" @handleSortSelect="handleSortSelect"
                     @handleTagSelect="handleTagSelect"
                     @modifyChapter="modifyChapter">
    </CommonHeaderNav>
    <div v-if="searchCardList === null">
      <ChapterHeader :chapter="chapterHeaderData" v-if="Object.keys(chapterHeaderData).length > 0"
                     @beginStudyClick="beginStudyClick" v-show="!$store.state.isSelectAll" class="mg-l-24 mg-r-24">
      </ChapterHeader>
      <div class="">
        <ChapterDetail v-if="chapterDetailList.length > 0" :chapterDetailList="chapterDetailList"
                       :recentAddCardList="recentAddCardList" :isShowRecentAddList="isShowRecentAddList"
                       @toPreview="toCardPreview"
                       @editorCard="editorCard" @openCreateCard="openCreateCard" @deletecChapter="deletecChapter"
                       @openLorRCreateCard="openLorRCreateCard" @openChapterOption="openChapterOption"
                       :isLoadData="isLoadData"
                       :showColumn="showColumn" :myChapterWidth="myChapterWidth" @addNewChapter="addNewChapter"
                       :cardList="cardList"
                       @changeModelSuccess="changeModelSuccess">
        </ChapterDetail>
      </div>
    </div>
    <card-list v-if="searchCardList !== null && searchCardList.length > 0" :cardList="searchCardList"
               @toCardPreview="toCardPreview1" @toEditorCard="toEditorCard" :isLoadData="isLoadData"
               :showColumn="showColumn"
               :myChapterWidth="myChapterWidth" :needPadding="true" @openChapterOption="openChapterOption" :page="page"
               @changeModelSuccess="changeModelSuccess">
    </card-list>
    <study-viewer ref="myStudyView" v-if="isStudy && Object.keys(firstCardModel).length > 0"
                  :studyCardData="studyCardData" @exitStudy="exitStudy" @editorCard="editorCard"
                  :isShowEditorCard="isShowEditorCard" :firstCardModel="firstCardModel" @openCreateCard="openCreateCard"
                  @editorLinkCard="editorLinkCard">
    </study-viewer>

    <card-viewer v-if="isPreview && Object.keys(firstCardModel).length > 0" ref="myCardViewer"
                 :studyCardData="studyCardData" @ClosePreview="closePreview"
                 :cardList="searchCardList === null?cardList:searchCardList"
                 :currentCardIndex="currentCardIndex" @previewToEditorCard="previewToEditorCard"
                 @deleteCardSuccess="deleteCardSuccess" @deleteCardListEmpty="deleteCardListEmpty"
                 :isShowEditorCard="isShowEditorCard" :firstCardModel="firstCardModel" @openCreateCard="openCreateCard"
                 @editorLinkCard="editorLinkCard">
    </card-viewer>

    <card-editor v-if="isShowEditorCard && Object.keys(firstCardModel).length > 0" :isEditorCard="isEditorCard"
                 :currentEditorCard="currentEditorCard" @closeEditor="closeEditor" :editorCardList="cardList"
                 @saveEditSuccess="saveEditSuccess" :isLeftAdd="isLeftAdd" :isRightAdd="isRightAdd"
                 :currentCardIndex="currentCardIndex" @deleteCardSuccess="deleteCardSuccess"
                 :isShowLeftOrRightBtn="isShowLeftOrRightBtn" :firstCardModel="firstCardModel"
                 @openImportData=openImportData
                 :isShowSelectChpater="isShowSelectChpater" :currentChapter="currentChapter"
                 :isEditLinkCard="isEditLinkCard">
    </card-editor>
    <el-dialog title="调整学习偏好" :visible.sync="isShowSetPlan" width="408px" :close-on-click-modal="false"
               :before-close="handleCancelClick" v-if="isShowSetPlan">
      <SetStudyPrefence :chapter="chapterHeaderData" ref="setPrefence" @initSuccess="adjustPlanSuccess">
      </SetStudyPrefence>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowSetPlan = false">取 消</el-button>
        <el-button type="primary" @click="saveSetPlan">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="选择记忆强度" :visible.sync="showDialog" width="408px" :close-on-click-modal="false"
               :before-close="() => { showDialog = false }">
      <SetStudyStrength @selectDegree="selectDegree" :planLrnLevel="chapterHeaderData.planLrnLevel"></SetStudyStrength>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="saveStrengthSet">确 定</el-button>
      </span>
    </el-dialog>
    <make-card title="修改卡牌盒" ref="makeChapter" :isFirstLevel="false" @saveAddChapter="saveAddChapter"
               :currentChapter="chapter1">
    </make-card>
    <init-study-plan v-if="showInitStudyPlan" :showInitStudyPlan="showInitStudyPlan"
                     @changeShowInitPlan="changeShowInitPlan" :chapter="chapterHeaderData"
                     @initPlanSuccess="initPlanSuccess">
    </init-study-plan>
    <BindPhone ref="bindPhone" @bindPhoneSuccess="bindPhoneSuccess"></BindPhone>
    <ShareChapter ref="shareChapter" :chapter="chapterHeaderData" :publishChapterDetailInfo="publishChapterDetailInfo"
                  @openPublish="openPublish" v-if="showShare">
    </ShareChapter>
    <Publish ref="publish" :chapter="chapterHeaderData" :publishChapterDetailInfo="publishChapterDetailInfo"
             v-if="showPublish">
    </Publish>
    <chapter-options ref="chapterOptions" @selectChapter="onSelectedChapter"></chapter-options>
    <import-data ref="importData"></import-data>
    <el-dialog title="增加学习量" :visible.sync="showAddStudyNum" width="475px"
               v-if="Object.keys(chapterHeaderData).length > 0">
      <div class="fs-16 display-flex justify-content-space-between mg-t-24 pd-b-16"
           style="border-bottom: 1px solid #eaeaea">
        <div>
          <div class="display-inline-block w-100">新卡</div>
          <div class="fs-14" style="color: rgba(32,32,32,.6);">剩余{{ chapterHeaderData.newCardNum[1] }}张</div>
        </div>
        <div class="display-flex align-items-center">
          <el-input-number v-model="addNew" :min="0" :max="chapterHeaderData.newCardNum[1]"
                           label="描述文字"></el-input-number>
        </div>
      </div>
      <div class="fs-16 justify-content-space-between mg-t-16 pd-b-16" style="border-bottom: 1px solid #eaeaea">
        <div>
          <div class="display-inline-block w-100">复习卡</div>
          <div class="fs-14" style="color: rgba(32,32,32,.6);">剩余{{ chapterHeaderData.revCardNum[1] }}张</div>
        </div>
        <div class="display-flex align-items-center">
          <el-input-number v-model="addRev" :min="0" :max="chapterHeaderData.revCardNum[1]"
                           label="描述文字"></el-input-number>
        </div>
      </div>
      <div class="fs-16 justify-content-space-between mg-t-16 pd-b-16">
        <div>
          <div class="display-inline-block w-100">提前复习</div>
          <div class="fs-14" style="color: rgba(32,32,32,.6);">剩余{{ chapterHeaderData.revCardNum[2] }}张</div>
        </div>
        <div class="display-flex align-items-center">
          <el-input-number v-model="addAdvanceRev" :min="0" :max="chapterHeaderData.revCardNum[2]"></el-input-number>
        </div>
      </div>
      <div class="fs-12 mg-t-10" style="color: rgba(32,32,32,.6);">
        Tip：所剩复习卡为今日应复习卡片，不进行学习将会延后安排
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancelShowAddNum">取 消</el-button>
        <el-button type="primary" @click="onSubmitAddNum">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import request from '../libs/api.request'
import {showMessage} from '../libs/message';
import {handleChapterOperation} from '../libs/message';
import {handleBatchCard} from '../libs/batch';
import {copyData} from '../libs/myClipboard';
import ChapterHeader from '../components/chapterDetail/ChapterHeader.vue';
import CommonHeaderNav from '../components/common/CommonHeaderNav.vue';
import ChapterDetail from '../components/chapterDetail/ChapterDetail.vue';
import StudyViewer from '../components/StudyViewer.vue';
import CardViewer from '../components/CardViewer.vue';
import CardEditor from '../components/CardEditor.vue';
import MakeCard from '../components/MakeCard.vue';
import SetStudyPlan from '../components/study/SetStudyPlan.vue';
import InitStudyPlan from '../components/study/InitStudyPlan.vue';
import SetStudyGoal from '../components/study/SetStudyGoal.vue';
import ShareChapter from '../components/study/ShareChapter.vue';
import BindPhone from '../components/common/BindPhone.vue';
import Publish from '../components/study/Publish.vue';
import CardList from '../components/CardList.vue';
import SetStudyStrength from '../components/study/SetStudyStrength.vue';
import SetStudyPrefence from '../components/study/SetStudyPrefence.vue';

import ChapterOptions from '../components/ChapterOptions.vue';
import ImportData from '../components/ImportData.vue';
import {debounce} from '../libs/analyse';

export default {
  // 一行显示的个数
  props: {
    showColumn: {
      type: Number,
    },
    myChapterWidth: {
      type: Number,
    }
  },
  components: {
    ChapterHeader,
    ChapterDetail,
    CommonHeaderNav,
    StudyViewer,
    CardEditor,
    CardViewer,
    SetStudyPlan,
    MakeCard,
    InitStudyPlan,
    SetStudyGoal,
    ShareChapter,
    BindPhone,
    Publish,
    CardList,
    ChapterOptions,
    ImportData,
    SetStudyStrength,
    SetStudyPrefence
  },
  data() {
    return {
      addNew: 5,
      addRev: 5,
      addAdvanceRev: 0,
      showAddStudyNum: false,
      showShare: false,
      showPublish: false,
      publishChapterDetailInfo: '',
      isShowSetPlan: false,
      showDialog: false,
      isShowSelectChpater: false,
      isStudy: false,
      isShowEditorCard: false,
      isShowBeginBTn: false,
      isPreview: false,
      showInitStudyPlan: false,
      chapterDetailList: [],
      chapterHeaderData: {},
      recentAddCardList: [], // 最近添加的卡牌列表
      isShowRecentAddList: false, // 判断是否显示最近添加，数量大于20就显示
      firstCardModel: {},
      currentEditorCard: {},
      isEditorCard: false,
      cardList: [],// 用于预览或者编辑里面的数组
      searchCardList: null, // 筛选或者搜索出来的卡片列表
      isLeftAdd: false,
      isRightAdd: false,
      isShowLeftOrRightBtn: true,
      currentIndex: 0,
      currentCardIndex: 0, // 要预览的卡牌的索引
      currentChapter: {}, // 要添加卡片的卡牌盒
      lrnLevel: '', // 难度等级 1=低、2=中、3=高、4强
      previewCardIndex: 0, // 预览卡片的卡片在chapterDetailList中的索引
      noMoreData: false,
      isLoadData: false,
      isEditLinkCard: false, //编辑的是否是双链卡牌
      // 筛选卡片
      page: 1,
      size: 30,
      status: '',
      keyword: '',
      flags: '',
      tags: '',
      sort: '',
      current: 0,// 卡片列表长度 ，， 用于筛选
      batchSelectedCard: [], // 批量选中的卡
      chapter1: {}
    }
  },
  methods: {
    // 批量更换模版成功
    changeModelSuccess() {
      if (this.searchCardList === null) {
        this.chapterDetailList = []
        this.current = 0
        this.getChapterListData()
      } else {
        this.page = 1
        this.getCardList()
      }
    },
    // 编辑双链卡牌
    editorLinkCard(card, model) {
      this.isEditLinkCard = true
      this.firstCardModel = model
      this.isShowSelectChpater = true
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
    },
    // 修改卡牌盒
    modifyChapter(chapter) {
      this.chapter1 = chapter
      this.$refs.makeChapter.open()
    },
    saveAddChapter(chapter) {
      this.getChapterInfo()
      for (let i = 0; i < this.chapterDetailList.length; i++) {
        if (this.chapter1.chapterId === this.chapterDetailList[i].chapterId) {
          this.chapterDetailList[i].name = chapter.name
          break
        }
      }
      this.$store.dispatch('modifyChapterList')
      this.$store.commit('setRenameChapter', chapter)
    },
    openChapterOption(batchSelectedCard) {
      // 批量选中的卡牌
      this.batchSelectedCard = batchSelectedCard
      this.$refs.chapterOptions.open();
    },
    openChapterOption(batchSelectedCard) {
      // 批量选中的卡牌
      this.batchSelectedCard = batchSelectedCard
      this.$refs.chapterOptions.open();
    },
    // 批量移动
    onSelectedChapter(chapter) {
      handleBatchCard(this, `将所选卡牌移动到【 ${chapter.name} 】?`, '移动', this.$refs.chapterOptions, {
          targetChapterId: chapter.chapterId,
          cardIds: this.batchSelectedCard
        },
        '/card/batchMove')
    },
    loadMoreSearchList() {
      if (this.noMoreData || this.isLoadData) {
        return
      }
      this.page++
      this.getCardList()
    },
    handleTagSelect(tags) {
      document.querySelector('.splitpanes__pane').scrollTop = 0
      this.tags = tags
      this.page = 1
      if (this.flags.length === 0 && this.status.length === 0 && this.tags.length === 0) {
        this.searchCardList = null
        return
      }
      this.getCardList()
    },
    handleSortSelect(sortId) {
      document.querySelector('.splitpanes__pane').scrollTop = 0
      this.sort = sortId
      this.page = 1
      this.current = 0
      if (this.flags.length === 0 && this.status.length === 0 && this.tags.length === 0 && sortId === '') {
        this.searchCardList = null
        return
      }
      if (this.searchCardList === null) {
        this.getChapterListData()
      } else {
        this.getCardList()
      }
    },
    handleSearch(keyword) {
      this.keyword = keyword.trim()
      document.querySelector('.splitpanes__pane').scrollTop = 0
      this.page = 1
      if (this.keyword.length === 0 && this.flags.length === 0 && this.status.length === 0 && this.tags.length === 0) {
        this.searchCardList = null
        return
      }
      this.getCardList()
    },
    handleFlagSelect(flag) {
      this.flags = flag
      document.querySelector('.splitpanes__pane').scrollTop = 0
      this.page = 1
      if (this.flags.length === 0 && this.status.length === 0 && this.tags.length === 0) {
        this.searchCardList = null
        return
      }
      this.getCardList()
    },
    handleStatusSelect(status) {
      this.status = status
      document.querySelector('.splitpanes__pane').scrollTop = 0
      this.page = 1
      if (this.flags.length === 0 && this.status.length === 0 && this.tags.length === 0) {
        this.searchCardList = null
        return
      }
      this.getCardList()
    },
    async getCardList() {
      this.isLoadData = true
      const res = await request.request({
        url: '/card/all',
        params: {
          chapterId: this.$route.params.chapterId,
          page: this.page,
          size: this.size,
          status: this.status,
          flags: this.flags,
          keyword: this.keyword,
          tags: this.tags,
          sort: this.sort
        }
      })
      this.isLoadData = false
      this.noMoreData = res.list.length < this.size
      if (this.page === 1) {
        this.searchCardList = res.list
      } else {
        this.searchCardList = this.searchCardList.concat(res.list)
      }
    },
    addNewChapter(chapter, index, newChapter) {
      let isFind = false
      for (let i = index + 1; i < this.chapterDetailList.length; i++) {
        if (this.chapterDetailList[i].itemType === 1 && this.chapterDetailList[i].level <= chapter.level) {
          // 如果找到同层级的卡牌盒，则下一个同层级的卡牌盒前面插入一个卡牌盒
          this.chapterDetailList.splice(i, 0, newChapter)
          isFind = true
          break
        } else {
          // 没找到
          isFind = false
        }
      }
      if (!isFind && this.noMoreData) {
        // 如果没有找到，并且没有更多数据，则往最后面插入一个
        this.chapterDetailList.push(newChapter)
      }
    },
    deletecChapter(chapter, index) {
      handleChapterOperation(this, `确定删除 ${chapter.name} 吗?此操作将会删除该卡牌盒下的所有卡牌`, '删除卡牌盒',
        '/chapter/delete', {chapterId: chapter.chapterId}, '删除成功',
        () => {
          this.getChapterInfo()
          this.$store.dispatch('modifyChapterList');
          let endIndex = this.chapterDetailList.length - 1;
          let hasChildCate = false
          for (let i = index + 1; i < this.chapterDetailList.length; i++) {
            if (this.chapterDetailList[i].itemType === 1 && this.chapterDetailList[i].level <= chapter.level) {
              // 从当前卡牌盒开始，删除后续的层级下的所有
              endIndex = index;
              this.chapterDetailList.splice(index, i - endIndex);
              hasChildCate = true
              break
            } else {
              hasChildCate = false
            }
          }
          //没有发现同层级的目录
          if (!hasChildCate) {
            this.chapterDetailList.splice(index);
            // 删除当前的目录下面的所有
          }
          // let oldLength = this.chapterDetailList.length;
          // console.log('删除过后的length',oldLength,'oldindex',oldLength);
          // if (oldLength - endIndex < 20) {
          //   //加载更多
          // }
        })
    },
    changeShowInitPlan(v) {
      this.showInitStudyPlan = v
    },
    // 调整学习计划成功
    adjustPlanSuccess() {
      this.isShowSetPlan = false
      this.getChapterInfo()
      this.$store.dispatch('modifyChapterList')
      this.$store.dispatch('modifyTotalChapterData')
    },
    // 第一次设置学习计划
    initPlanSuccess() {
      this.showInitStudyPlan = false
      this.getChapterInfo()
      this.beginStudyClick()
    },
    // 保存学习计划
    saveSetPlan() {
      this.$refs.setPrefence.finishSettingPlan()
    },
    // 保存记忆强度设置
    saveStrengthSet() {
      request.request({
        url: '/chapter/savePlan',
        method: 'post',
        data: {
          lrnLevel: this.lrnLevel || this.chapterHeaderData.planLrnLevel,
          chapterId: this.chapterHeaderData.chapterId
        }
      }).then(res => {
        showMessage({message: '设置成功!', type: 'success'})
        this.showDialog = false
        this.chapterHeaderData.planLrnLevel = this.lrnLevel || this.chapterHeaderData.planLrnLevel
      }).catch(e => {
        showMessage({message: e.message, type: 'error'})
      })
    },
    selectDegree(id) {
      this.lrnLevel = id * 1
      console.log(this.lrnLevel);
    },

    setPlanSuccess() {
      this.isShowSetPlan = false
      this.getChapterInfo()
    },
    handleCancelClick() {
      this.isShowSetPlan = false
    },
    openAdjustPlan() {
      if (this.chapterHeaderData.planId !== 1) {
        // 直接打开调整计划
        this.isShowSetPlan = true
      } else {
        // 初始学习计划
        this.showInitStudyPlan = true
      }
    },
    beginStudyClick: debounce(function (noTryAgain) {
      // 卡牌盒planId为1的话，第一次就要设置学习强度盒和学习计划,否则直接正常学习
      if (this.chapterHeaderData.cardNum === 0) {
        showMessage({message: '当前卡牌盒暂无卡牌，无法开始学习', type: 'error'})
        return
      }
      if (this.chapterHeaderData.planId == 1) {
        this.showInitStudyPlan = true
      } else {
        if (this.chapterHeaderData.newCardNum[0] + this.chapterHeaderData.revCardNum[0] !== 0) {
          // 今日计划没学完
          console.log('new');
          this.$myLoading.start({bgcColor: 'rgba(247, 247, 247, .9)'})
          request.request({
            url: '/chapter/study',
            method: 'post',
            data: {
              studyChapterId: this.chapterHeaderData.chapterId,
            }
          }).then(res => {
            this.$myLoading.finishProgress()
            this.$myLoading.finish()
            this.studyCardData = res
            this.getCardModel(res.card.modelId, () => {
              this.isStudy = true
            })
          }).catch(err => {
            showMessage({message: err, type: 'error'})
            this.showLoading = false
          })
        } else {
          if (this.chapterHeaderData.newCardNum[1] + this.chapterHeaderData.revCardNum[1] + this.chapterHeaderData.revCardNum[2] !== 0) {
            if (noTryAgain === true) {
              return;
            }
            this.showAddStudyNum = true
            console.log('add');
            // 添加学习量

          } else {
            return showMessage({message: '此卡牌盒已学完或者没有卡牌，请刷新当前页面', type: 'error'})
          }
        }
      }
    }, 300, false),
    // 左或者右添加卡牌
    openLorRCreateCard(card, index, direction) {
      if (Object.keys(this.$store.state.selectedModel).length > 0) {
        this.firstCardModel = this.$store.state.selectedModel
      } else {
        this.firstCardModel = this.$store.state.modelList[0]
      }
      this.currentEditorCard = card
      this.currentCardIndex = index
      if (direction === 'left') {
        this.isLeftAdd = true
      } else {
        this.isRightAdd = true
      }
      this.isShowEditorCard = true
    },
    getCardModel(modelId, callback) {
      request.request({
        url: "model/detail",
        params: {
          modelId
        }
      }).then(res => {
        this.firstCardModel = res
        if (callback) {
          callback()
        }
      })
    },
    exitStudy(hasChange) {
      this.isStudy = false
      if (hasChange) {
        // 如果点击了学习则要刷新一下
        this.getChapterInfo()
        this.$store.dispatch('modifyChapterList')
        this.$store.dispatch('modifyAllChapterList')
      }
    },
    // 编辑卡牌
    saveEditSuccess(card, index, isEdit = false) {
      // index是左加右加才有的
      card.itemType = 2
      if (this.isStudy) {
        if (!isEdit) {
          console.log('学习时新增');
          for (let i = 0; i < this.chapterDetailList.length; i++) {
            if (this.chapterDetailList[i].itemType === 1 && this.chapterDetailList[i].chapterId === card.chapterId) {
              this.chapterDetailList.splice(i + 1, 0, card)
              break
            }
          }
        } else {
          this.$refs.myStudyView.rendHtml()
          console.log('学习时编辑');
        }
        this.isShowEditorCard = false
      } else if (this.isPreview) {
        if (!isEdit) {
          console.log('预览时新增卡片');
          this.chapterDetailList.splice(index, 0, card)
        } else {
          console.log('预览时编辑卡片');
          this.chapterDetailList.splice(this.previewCardIndex, 1, card)
        }
        this.isShowEditorCard = false
        this.$refs.myCardViewer.rendHtml()
      } else if (this.isLeftAdd) {
        this.chapterDetailList.splice(index, 0, card)
        console.log('左加');
      } else if (this.isRightAdd) {
        this.chapterDetailList.splice(index + 1, 0, card)
        console.log('右加');
      } else if (this.isEditorCard) {
        //编辑卡片
        console.log('编辑卡片');
        for (let i = 0; i < this.chapterDetailList.length; i++) {
          if (this.chapterDetailList[i].cardId && this.chapterDetailList[i].cardId === card.cardId) {
            this.chapterDetailList.splice(i, 1, card)
            break
          }
        }
        if (this.searchCardList !== null) {
          this.searchCardList.splice(index, 1, card)
        }
      } else {
        // 新增卡片
        console.log('新增卡片卡片', card);
        if (Array.isArray(card)) {
          // 添加正反面卡的时候
          for (let i = 0; i < this.chapterDetailList.length; i++) {
            if (this.chapterDetailList[i].itemType === 1 && this.chapterDetailList[i].chapterId === card[0].chapterId) {
              this.chapterDetailList.splice(i + 1, 0, card[0])
              this.chapterDetailList.splice(i + 1, 0, card[1])
              break
            }
          }
        } else {
          // 添加别的卡的时候
          for (let i = 0; i < this.chapterDetailList.length; i++) {
            if (this.chapterDetailList[i].itemType === 1 && this.chapterDetailList[i].chapterId === card.chapterId) {
              this.chapterDetailList.splice(i + 1, 0, card)
              break
            }
          }
        }
      }
    },
    deleteCardSuccess(index) {
      this.cardList.splice(index, 1)
    },
    openImportData(chapter) {
      if (this.isStudy) {
        this.isStudy = false
      }
      if (this.isPreview) {
        this.isPreview = false
      }
      this.isShowEditorCard = false
      setTimeout(() => {
        this.$refs.importData.chapter = chapter
        this.$refs.importData.open()
      }, 200);
    },
    // 筛选出来的卡牌列表的卡片编辑
    toEditorCard(card, index) {
      this.currentCardIndex = index
      this.isShowSelectChpater = true
      this.isEditorCard = true
      this.currentEditorCard = card
      this.getCardModel(card.modelId, () => {
        this.isShowEditorCard = true
      })

    },
    // 目录下面的编辑按钮或者预览处编辑卡牌编辑卡牌, 这个cardList 是当前点击的卡牌所属的层级下面的卡牌列表
    editorCard(card, model) {
      if (!this.isStudy) {
        console.log('非学习进入编辑');
        this.getCardModel(card.modelId)
      } else {
        console.log('学习的时候的model', model);
        this.firstCardModel = model
      }
      for (let i = 0; i < this.cardList.length; i++) {
        if (this.cardList[i].cardId === card.cardId) {
          this.currentCardIndex = i
          break
        }
      }
      this.isShowSelectChpater = true
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
    },
    // 进入卡牌预览
    toCardPreview(card, index) {
      console.log(index);
      this.previewCardIndex = index
      for (let i = 0; i < this.cardList.length; i++) {
        if (this.cardList[i].cardId === card.cardId) {
          this.currentCardIndex = i
          break
        }
      }
      this.studyCardData = card
      this.getCardModel(card.modelId, () => {
        this.isPreview = true
      })

    },
    // 从搜索到的list里面进入预览
    toCardPreview1(card, index) {
      this.currentCardIndex = index
      this.studyCardData = card
      this.getCardModel(card.modelId, () => {
        this.isPreview = true
      })
    },
    // 添加卡片
    openCreateCard(curCard) {
      console.log(curCard);
      this.currentChapter = curCard
      this.isEditorCard = false
      if (this.isPreview || this.isStudy) {
        this.isShowSelectChpater = true
      }
      this.isShowEditorCard = true
      this.isShowLeftOrRightBtn = false
      if (Object.keys(this.$store.state.selectedModel).length > 0) {
        this.firstCardModel = this.$store.state.selectedModel
      } else {
        this.firstCardModel = this.$store.state.modelList[0]
      }
    },
    closePreview() {
      this.isPreview = false
      this.firstCardModel = {}
      if (!this.searchDialogVisible) {
        // showScrollBar()
      }
    },
    deleteCardListEmpty() {
      this.isPreview = false
    },
    // 学习页中到编辑
    studyToEditorCard(card, index) {
      this.isShowSelectChpater = false
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
      this.currentCardIndex = index * 1
    },
    // 预览处编辑卡牌到编辑页
    previewToEditorCard(card, index, model) {
      this.isShowEditorCard = true
      this.isEditorCard = true
      this.currentEditorCard = card
      this.currentCardIndex = index * 1
      this.firstCardModel = model
      this.isShowSelectChpater = true
    },
    // 关闭卡牌编辑
    closeEditor(hasSaveCard, hasDelete, hasMove) {
      if (hasSaveCard) {
        this.getChapterInfo()
        this.$store.dispatch('modifyChapterList')
      }
      if (hasMove) {
        location.reload()
        return
      }
      if (this.isPreview || this.isStudy) {
      } else {
        this.firstCardModel = {}
      }
      this.isShowSelectChpater = false
      this.isLeftAdd = false
      this.isRightAdd = false
      this.isShowLeftOrRightBtn = true
      this.isShowEditorCard = false
      this.isEditorCard = false
      this.isEditLinkCard = false
      this.currentChapter = {}
      this.currentEditorCard = {}
    },
    handleOpenShare() {
      if (this.chapterHeaderData.cardNum === 0) return showMessage({
        message: '卡牌盒没有卡片，无法开启分享',
        type: 'error'
      })
      if (this.chapterHeaderData.creatorId !== this.chapterHeaderData.userId) {
        let copyUrl = "https://store.amazingmemo.com/chapterDetail/" + this.$route.params.chapterId
        copyData(copyUrl)
        return
      }
      if (this.$store.state.userInfo.phone === null || this.$store.state.userInfo.phone === undefined) {
        this.$refs.bindPhone.open()
      } else {
        this.$refs.shareChapter.open()
      }
    },
    bindPhoneSuccess() {
      this.$refs.shareChapter.open()
    },
    openPublish() {
      this.$refs.publish.open()
    },
    onCancelShowAddNum() {
      this.showAddStudyNum = false
      this.addNew = 5
      this.addRev = 5
      this.addAdvanceRev = 0
    },
    onSubmitAddNum() {
      if (this.addNew < 0) return showMessage({message: '新卡量不能小于0', type: 'error'})
      if (this.addRev < 0) return showMessage({message: '复习卡量不能小于0', type: 'error'})
      if (this.addAdvanceRev < 0) return showMessage({message: '提前复习的卡片量不能小于0', type: 'error'})
      request.request({
        url: '/chapter/addStudyNum',
        method: "post",
        data: {
          chapterId: this.chapterHeaderData.chapterId,
          addNew: this.addNew,
          addRev: this.addRev,
          addAdvanceRev: this.addAdvanceRev
        }
      }).then(res => {
        this.$store.dispatch('modifyChapterList')
        showMessage({message: '设置成功', type: 'success'})
        this.showAddStudyNum = false
        request.request({
          url: "/chapter/info",
          params: {
            chapterId: this.$route.params.chapterId
          }
        }).then(data => {
          this.chapterHeaderData = data
          let t = setTimeout(() => {
            this.beginStudyClick(true);
            clearTimeout(t)
          }, 100)
        })
      }).catch(e => {
        showMessage({message: e.message, type: 'error'})
      })
    },
    async getChapterListData() {
      this.isLoadData = true
      const res = await request.request({
        url: "/chapter/mixList",
        params: {
          chapterId: this.$route.params.chapterId,
          current: this.current,
          limit: 50, //获取的长度
          sort: this.sort
        }
      })
      this.isLoadData = false
      this.noMoreData = res.list.length < 50
      if (this.current === 0) {
        this.chapterDetailList = res.list
      } else {
        this.chapterDetailList = this.chapterDetailList.concat(res.list)
      }
      this.current = this.chapterDetailList.length
      this.cardList = this.chapterDetailList.filter(e => {
        return e.itemType === 2
      })
    },
    async getChapterInfo() {
      const res = await request.request({
        url: '/chapter/info',
        params: {
          chapterId: this.$route.params.chapterId,
        }
      })
      this.chapterHeaderData = res
      this.publishChapterDetailInfo = res.pubInfo
      this.showPublish = true
      this.showShare = true
    },
    // async getRecenCardtList() {
    //   const res = await request.request({
    //     url: '/card/latelyCreate',
    //     params: {
    //       chapterId: this.$route.params.chapterId,
    //     }
    //   })
    //   this.isShowRecentAddList = res.list.length >= 20
    //   this.recentAddCardList = res.list.slice(0, this.showColumn * 2)
    // },

    loadMoreData() {
      if (this.noMoreData || this.isLoadData) {
        return
      }
      this.getChapterListData()
    },
    // 获取文档高度
    getScrollHeight() {
      let scrollHeight = document.querySelector('.my-chapter').scrollHeight
      return scrollHeight
    },
    // 窗口总高度
    getClientHeight() {
      let listClientHeight = document.body.clientHeight || document.documentElement.clientHeight
      return listClientHeight
    },
    //滚动的高度
    getScrollTop() {
      let scrollTop = document.querySelector('.customPane').scrollTop
      return scrollTop
    },
    handleScroll() {
      if (this.$route.name === 'Chapter') {
        if (this.getClientHeight() + this.getScrollTop() >= this.getScrollHeight() - 3000) {
          if (this.searchCardList === null) {
            // 加在mixlist
            this.loadMoreData()
          } else {
            // 加在更多筛选到的卡片
            this.loadMoreSearchList()
          }
        }
        if (this.getScrollTop() > 170) {
          this.isShowBeginBTn = true
        } else {
          if (this.isShowBeginBTn === false) {
            return
          }
          this.isShowBeginBTn = false
        }
      }
    },
    async getChapterData() {
      // this.$myLoading.start({ bgcColor: 'rgba(247, 247, 247, .9)' })
      await this.getChapterListData()
      await this.getChapterInfo()
      // await this.getRecenCardtList()

      // this.$myLoading.finishProgress()
      // this.$myLoading.finish()
    }
  },
  watch: {
    $route() {
      if (this.$route.name === 'Chapter' || this.$route.name === 'CardAll') {
        document.querySelector('.splitpanes__pane').scrollTop = 0
      }
      if (this.$route.name !== 'Chapter') return
      this.current = 0
      this.tags = []
      this.status = ''
      this.flags = ''
      this.chapterDetailList = []
      this.searchCardList = null
      this.getChapterData()
    },
    // showColumn() {
    //   this.getRecenCardtList()
    // }
    chapterDetailList(newList) {
      this.cardList = newList.filter(e => {
        return e.itemType === 2
      })
    }
  },
  created() {
    if (localStorage.getItem('sort')) {
      console.log(localStorage.getItem('sort'));
      this.sort = localStorage.getItem('sort')
    }
    this.getChapterData()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)

  },
  activated() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  deactivated() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }
}
</script>

<style lang="scss" scoped>
.el-dialog__footer {
  .dialog-footer {
    ::v-deep .el-button {
      border-radius: 6px;
      width: 112px !important;
      height: 36px;
      padding: 0 !important;
      margin-left: 16px !important;
    }
  }
}

::v-deep .el-dialog {
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
}

::v-deep .el-dialog__title {
  font-weight: bold;
  color: #202020;
  font-size: 16px;
}


::v-deep .el-dialog__footer {
  padding-bottom: 32px;
}

::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>
