<template>
  <!-- 设置学习强度 -->
    <div>
      <div class="plan-desc">记忆卡强度越高，卡片安排复习频率越高，花费时间越多</div>
      <div class="plan-degree" >
        <div
          class="plan-item"
          v-for="(item,index) in degreeList"
          :key="item.id"
          @click="degreeClick(item, index)"
          :class="setPlanLrnLevel === item.id || currentIndex === index ? 'active' : ''"
          :style="{ borderColor: currentIndex === index ? item.bgColor : '' }"
        >
          <div class="content">
            <div class="label" :style="{ backgroundColor: item.bgColor }">{{ item.degree }}</div>
            <div>{{ item.name }}</div>
          </div>
          <img
            src="../../assets/images/icons_filled_toggle_PC_light_tick-circle_24pt@2x.png"
            class="degree-img"
            v-if="setPlanLrnLevel === item.id || currentIndex === index"
          />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props:{
      planLrnLevel:{
        type: Number
      }
    },
    data() {
      return {
        currentIndex: '',
        degreeList: [
          {
            degree: '强',
            bgColor: '#FF4B3E',
            name: '核心内容，需要高频复习',
            id: 4
          },
          {
            degree: '高',
            bgColor: '#FF8800',
            name: '大多数情况推荐',
            id: 3
          }, {
            degree: '中',
            bgColor: '#FFB700',
            name: '较少安排复习',
            id: 2
          }, {
            degree: '低',
            bgColor: '#52CCB4',
            name: '尽量不安排复习',
            id: 1
          },
        ],
        setPlanLrnLevel: this.planLrnLevel
      }
    },
    methods: {
      degreeClick(item, index) {
        this.currentIndex = index
        this.setPlanLrnLevel = ''
        this.$emit('selectStrength',item.id)
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import "@/assets/css/variable.scss";
  
  .plan-desc {
    margin-bottom: 20px;
    color: rgba(32, 32, 32, 0.6);
  }
  .plan-degree {
    .plan-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid rgba(32, 32, 32, 0.16);
      margin-bottom: 16px;
      &.active {
        border: 2px solid #ff8800;
      }
      &:hover {
        cursor: pointer;
      }
      .content {
        display: flex;
        align-items: center;
        .label {
          font-size: 16px;
          padding: 4px 18px;
          margin-right: 12px;
          border-radius: 6px;
          color: #fff;
        }
      }
      .degree-img {
        width: 20px;
        height: 20px;
      }
    }
  }
  </style>