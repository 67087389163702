<template>
  <el-dialog :title="title" :visible.sync="_showInitStudyPlan" width="420px" :destroy-on-close="true">
    <template v-if="step == 1">
      <set-study-scene :chapter="chapter" @selectScene="selectScene" :planMemoScene="planMemoScene"></set-study-scene>
    </template>
    <template v-if="step == 2">
      <set-study-goal @selectGoal="selectGoal" :planMemoScene="planMemoScene" :planMemoObj="planMemoObj" :ifFirst="true">
      </set-study-goal>
    </template>
    <template v-if="step == 3">
      <set-study-plan :chapter="chapter" @setPlanMemoTime="setPlanMemoTime" :planMemoScene="planMemoScene"
        @setPlanNewCardNum="setPlanNewCardNum">
      </set-study-plan>
    </template>
    <template v-if="step == 4">
      <set-study-prefence :chapter="chapter" ref="setPrefence" 
        :planMemoScene="planMemoScene" :isFirst="true" @initSuccess="initPlanSuccess">
      </set-study-prefence>
    </template>
    <div class="next-btn">
      <el-button @click="onPreStepClick" v-if="step !== 1">上一步</el-button>
      <el-button type="primary" @click="onNextStepClick" class="step-btn">{{ step === 4 ? '完成' : '下一步' }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import request from '../../libs/api.request'
import SetStudyPlan from "./SetStudyPlan.vue"
import SetStudyPrefence from "./SetStudyPrefence.vue"
import SetStudyGoal from "./SetStudyGoal.vue"
import SetStudyScene from './SetStudyScene.vue'
import { showMessage } from '../../libs/message'

export default {
  props: {
    chapter: {
      type: Object,
      default: () => {
      }
    },
    showInitStudyPlan: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    SetStudyPlan,
    SetStudyGoal,
    SetStudyScene,
    SetStudyPrefence
  },
  data() {
    return {
      title: '选择记忆场景',
      step: 1,
      lrnLevel: this.chapter.planLrnLevel,// 记忆强度
      planNewCardNum: 0, // 新卡数量
      planMemoScene: 1, // 记忆场景，1长期 2考试
      planMemoObj: 2, // 学习目标 1简单了解 2初步掌握 3熟练运用
      planMemoTime: 30 // 长期记忆传分钟数，考试传日期如2022-12-12
    }
  },
  methods: {
    initPlanSuccess() {
      this.$emit('initPlanSuccess')
    },
    onPreStepClick() {
      switch (this.step) {
        case 2:
          this.title = '选择记忆场景'
          this.step = 1
          break;
        case 3:
          this.title = '选择学习目标'
          this.step = 2
          break;
        case 4:
          if (this.planMemoScene === 2) {
            // 准备考试
            this.title = '选择考试日期'
          } else {
            // 长期学习
            this.title = '设置每天学习量'
          }
          this.planNewCardNum = 0
          this.step = 3
          break;
      }
    },
    onNextStepClick() {
      switch (this.step) {
        case 1:
          this.title = '选择学习目标'
          this.step = 2
          break;
        case 2:
          if (this.planMemoScene === 2) {
            // 准备考试
            this.title = '选择考试日期'
            this.planMemoObj = 3
          } else {
            // 长期学习
            this.title = '设置每天学习量'
          }
          this.step = 3
          break;
        case 3:
          if (this.planMemoScene == 2) {
            this.planNewCardNum = 0
          }
          request.request({
            url: '/chapter/savePlanByScene',
            method: "post",
            data: {
              chapterId: this.chapter.chapterId,
              planMemoScene: this.planMemoScene,
              planMemoObj: this.planMemoObj,
              planMemoTime: this.planMemoTime,
              planNewCardNum: this.planNewCardNum
            }
          }).then(async res => {
            await this.$parent.getChapterInfo()
            this.title = '调整学习偏好'
            this.step = 4
          }).catch(e=> {
            showMessage({message:e.message,type:'error'})
          })
          break;
        case 4:
          this.$refs.setPrefence.finishSettingPlan()
          break
      }
    },
    setPlanNewCardNum(num) {
      this.planNewCardNum = num
    },
    setPlanMemoTime(time) {
      this.planMemoTime = time
    },
    selectScene(scene) {
      // 设置记忆场景 1 是长期，2是考试
      this.planMemoScene = scene
    },
    selectGoal(goal) {
      // 设置学习目标
      this.planMemoObj = goal
    },
    selectDegree(id) {
      this.lrnLevel = id
      this.chapter.planLrnLevel = id
    },
    toNextStepClick() {
      if (this.lrnLevel === '' || this.lrnLevel === undefined) {
        return showMessage({ message: '保存成功!', type: 'warning' })
      }
      this.title = '调整学习偏好'
      this.showPreStep = false
    },
    backPreStepClick() {
      this.title = '选择记忆强度'
      this.showPreStep = true
    }
  },
  computed: {
    _showInitStudyPlan: {
      get() {
        return this.showInitStudyPlan
      },
      set(v) {
        this.$emit('changeShowInitPlan', v)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.next-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  ::v-deep .el-button {
    width: 112px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

::v-deep .el-dialog {
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
  background-color: #f7f7f7;
}

::v-deep .el-dialog__header {
  padding: 32px 24px 28px 24px;
}

::v-deep .el-dialog__body {
  padding: 0 24px 32px 24px;
}
</style>
