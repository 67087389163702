<template>
  <div class="plan-num">
    <div class="plan-deadline">
      <div class="text">距离考试日期</div>
      <div class="time">{{ surplusDay }}</div>
    </div>
    <el-date-picker v-model="planMemoTime" type="date" placeholder="选择考试日期" format="yyyy 年 MM 月 dd 日"
      value-format="yyyy-MM-dd" style="width:100%;margin-top: 20px;" :picker-options="pickerOptions">
    </el-date-picker>
    <div class="display-flex justify-content-center mg-t-20">
      <el-button type="primary" class="set-btn" @click="onSetClick">设置</el-button>
    </div>
  </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
  props: {
    chapter: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      planMemoTime: this.chapter.planMemoTime,
      pickerOptions: {
        disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;//  - 86400000是否包括当天
        }
      },
    }
  },
  methods: {
    onSetClick() {
      this.$emit('SetStudyTime',this.planMemoTime)
    }
  },
  computed: {
    surplusDay() {
      // 剩余天数
      let now = dayjs(dayjs((new Date).getTime()).format('YYYY-MM-DD'))
      if (isNaN(dayjs(this.planMemoTime).diff(now, 'hour'))) {
        return ''
      }
      if (dayjs(this.planMemoTime).diff(now, 'hour') <= 0) {
        return '0天'
      } else {
        return dayjs(this.planMemoTime).diff(now, 'hour') / 24 + '天'
      }
    }
  },
  watch:{
    chapter(n){
      this.planMemoTime = n.planMemoTime
    }
  }
}
</script>
<style lang="scss" scoped>
.plan-num {

  .set-btn {
    width: 80%;
    border-radius: 8px;
  }

  .plan-deadline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    background-color: #fff;
    border-radius: 12px;
    padding: 0 8px 0 18px;

    .text {
      font-size: 16px;
      font-weight: 500;
      color: #202020;
    }

    .time {
      font-size: 16px;
      font-weight: 500;
      color: #FFA800;
    }
  }
}
</style>