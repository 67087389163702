<template>
  <div class="chapter-detail">
    <draggable :list="chapterDetailList" group="chapter" forceFallback="true"
      style="width:100%;padding-left: 24px;padding-right: 24px;" animation="1000" @start="onStart" @end="onEnd"
      filter=".forbid" fallbackTolerance="100" :move="onMove" class="display-flex flex-wrap">
      <div class="chapter-detail-item " :key="index" v-for="(chapter, index) in chapterDetailList"
        :style="{ marginLeft: adjustMarginLeft(chapter.level), width: chapter.itemType === 1 ? '100%' : '' }">
        <template v-if="chapter.itemType === 1">
          <div class="chapter-detail-item-container " @mouseenter="handleMouseenter(chapter, index)"
            @mouseleave="handleMouseleave(chapter, index)" v-show="chapter.isShow">
            <div class="chapter-detail-item-name">
              <div class="icon-box">
                <svg-icon iconClass="chapter_add_menu" class="addMenu cursor-pointer" v-show="chapter.isShowAdd"
                  @click="chapter.isShowMenu = !chapter.isShowMenu">
                </svg-icon>
                <svg-icon @click.stop="handleExpansion(chapter, index)"
                  :iconClass="chapter.isShowExpansion ? 'arrow_toggle_right' : 'arrow_toggle_down'"
                  class="w-20 h-20 mg-l-8 mg-r-1 cursor-pointer">
                </svg-icon>
                <el-collapse-transition>
                  <div class="my-add-menu-box" v-show="chapter.isShowMenu" @mouseleave="chapter.isShowMenu = false">
                    <div class="add-menu-item" v-for="menu in menuList" :key="menu.id"
                      @click.stop="onMenuClick(chapter, menu, index)">
                      <svg-icon :iconClass="menu.icon" class="w-16 h-16"></svg-icon>
                      <div class="menu-name">{{ menu.name }}</div>
                    </div>
                  </div>
                </el-collapse-transition>
              </div>
              <div class="chapter-detail-item-title">
                <el-input type="textarea" autosize v-model="chapter.name"
                  @keydown.enter.native="onKeyupEnter($event, chapter, index)" @input="editorNewChapter(chapter, index)"
                  :ref="chapter.chapterId">
                </el-input>
              </div>
            </div>
            <div class="chapter-detail-item-cardnum cursor-pointer" @click="handleChapterNameClick(chapter)">
              <div class="cardnum">{{ chapter.cardNum }}</div>
              <svg-icon iconClass="arrow_right" class="w-14 h-14"></svg-icon>
            </div>
          </div>
        </template>

        <!-- 卡片列表 -->
        <!-- opacity: chapter.queue < 0 ? '0.35' : '1',  -->
        <template class="" v-if="chapter.itemType === 2">
          <div class="card not-select" @click.stop="handleCardClick(chapter, index)" :class="isActive(chapter, index)"
            :style="{ background: fetchCardBgc1(chapter.flags), width: getShowColumn(showColumn) }"
            v-show="chapter.isShow">
            <div class="card-content">
              <div class="card-title" v-html="chapter.fld1" v-if="chapter.fld1.trim().length > 0"></div>
              <div class="card-detail" :class="chapter.image ? 'clip1' : 'clip5'" v-html="chapter.fld2"></div>
              <img v-if="chapter.image" class="card-img shadow-2" :src="chapter.image" onerror="onImageLoadErr(this)" />
            </div>
            <div class="card-label-container">
              <div class="card-type">
                <div class="card-type-item" v-if="holdMap[chapter.status]">
                  <svg-icon :iconClass="holdMap[chapter.status].icon"></svg-icon>
                  <div class="card-type-text">{{ holdMap[chapter.status].name }}</div>
                </div>
              </div>
              <div class="card-created-time" v-if="chapter.lapses > 3">
                <svg-icon iconClass="forget"></svg-icon>
                忘记{{ chapter.lapses }}次
              </div>
              <div class="card-created-time" v-else>{{ modifyTime(chapter.nmod) }}</div>
            </div>
            <div class="edit-box menu-box" @click.stop="editCard(chapter, index)">
              <svg-icon icon-class="card_edit_menu"></svg-icon>
            </div>
            <div class="flag-box menu-box">
              <div class="flag-icon-box" :style="{ background: fetchCardBgc(chapter.flags) }">
                <svg-icon icon-class="white_flag" v-if="chapter.flags !== 0"></svg-icon>
                <svg-icon icon-class="search_flag" v-else></svg-icon>
              </div>
              <div class="flag-menu-container">
                <div class="flag-menu-box">
                  <div class="flag-menu-item" @click.stop="flagClick(chapter, 2)">
                    <div class="item item1"></div>
                  </div>
                  <div class="flag-menu-item mg-l-6 mg-r-6" @click.stop="flagClick(chapter, 1)">
                    <div class="item item2"></div>
                  </div>
                  <div class="flag-menu-item mg-r-10" @click.stop="flagClick(chapter, 3)">
                    <div class="item item3"></div>
                  </div>
                  <svg-icon icon-class="card_flag_clear_menu" @click.stop="flagClick(chapter, 0)"></svg-icon>
                </div>
              </div>
            </div>
            <div class="more-menu-box menu-box" @click.stop="onShowMoreMenu(chapter)"
              @mouseenter="chapter.isShowMoreMenu = true" @mouseleave="chapter.isShowMoreMenu = false">
              <svg-icon icon-class="chapter_more_menu"></svg-icon>
              <!-- <el-collapse-transition> -->
              <transition name="el-zoom-in-bottom">
                <div class="more-menu" v-show="chapter.isShowMoreMenu" @mouseleave="chapter.isShowMoreMenu = false">
                  <div class="more-menu-item" v-for="menu in moreMenuList" :key="menu.id"
                    @click.stop="handleMoreClick(menu, chapter, index)">
                    <div v-if="menu.id === 5">{{ chapter.queue === -1 ? '恢复学习' : menu.name }}</div>
                    <div v-else>{{ menu.name }}</div>
                    <svg-icon :iconClass="menu.icon"></svg-icon>
                  </div>
                </div>
              </transition>
              <!-- </el-collapse-transition> -->
            </div>
            <div class="card-mod"></div>
          </div>
        </template>
      </div>
    </draggable>
    <div class="clear"></div>
    <BatchNav :selectCardProp="selectCard" :batchSelectedCardProp="batchSelectedCard" :list="cardList"
      @upDateSelectCard="upDateSelectCard" @openChapterOption="openChapterOption"
      @changeModelSuccess="changeModelSuccess"></BatchNav>
  </div>
</template>

<script>
import CardList from '../CardList.vue'
import BatchNav from '../common/BatchNav.vue'
import draggable from 'vuedraggable'
import request from '../../libs/api.request'
import dayjs from 'dayjs'
import { showMessage } from '../../libs/message'
import { handleBatchCard } from '../../libs/batch'
import { debounce } from '../../libs/analyse'
export default {
  props: {
    chapterDetailList: {
      type: Array,
      default: () => []
    },
    // 全部的卡片列表
    cardList: {
      type: Array,
      default: () => []
    },
    recentAddCardList: {
      type: Array,
      default: () => []
    },
    enableBatchOption: {
      type: Boolean,
      default: true
    },
    isLoadData: {
      type: Boolean,
      default: false
    },
    showColumn: {
      type: Number,
    },
    myChapterWidth: {
      type: Number,
    }
  },
  components: {
    CardList,
    draggable,
    BatchNav,
  },
  data() {
    return {
      showMenu: false,
      holdMap: {
        1: { icon: 'new_card', id: 1, name: '新卡' },
        2: { icon: 'studying', id: 2, name: '记忆中' },
        3: { icon: 'handle', id: 3, name: '已掌握' },
        5: { icon: 'no_review', id: 5, name: '不复习' }
      },
      menuList: [
        { icon: 'chapter-add-card', name: '添加卡片', id: 1 },
        { icon: 'add_folder', name: '新建卡牌盒', id: 2 },
        { icon: 'chapter-delete', name: '删除', id: 3 }
      ],
      moreMenuList: [
        { name: '左边插入', icon: 'left_add', id: 1 },
        { name: '右边插入', icon: 'right_add', id: 2 },
        { name: '批量管理', icon: 'batch_all', id: 3 },
        { name: '停学卡片', icon: 'stop_study', id: 5 },
        { name: '删除卡片', icon: 'delete_card', id: 4 },
      ],
      batchSelectedCard: [],//批量操作选中的卡，如果当前是全选状态则是排除的
      selectCard: [],
      targetChapterId: '',// 拖拽后所在的卡牌盒id
      position: '' // 卡牌移动之后，所在卡牌盒的position
    }
  },
  methods: {
    changeModelSuccess() {
      this.$emit('changeModelSuccess')
    },
    upDateSelectCard(batchSelectedCard, selectCard) {
      this.batchSelectedCard = batchSelectedCard
      this.selectCard = selectCard
    },
    onStart(e) {
      console.log('开始', e);
    },
    onEnd(e) {
      console.log('结束', e);
      if (e.newIndex === e.oldIndex) return
      const cardId = e.to.__vue__.context.element.cardId
      console.log('newindex', e.newIndex);
      for (let i = e.newIndex; i < e.target.__vue__.list.length; i--) {
        if (e.target.__vue__.list[i].itemType === 1) {
          this.targetChapterId = e.target.__vue__.list[i].chapterId
          console.log(i);
          this.position = e.newIndex - i
          break
        }
      }
      request.request({
        url: '/card/move',
        method: 'post',
        data: {
          cardId: cardId,
          chapterId: this.targetChapterId,
          position: this.position
        }
      }).then(res => {
        showMessage({ message: '移动成功!', type: 'success' })
      }).catch(e => {
        showMessage({ message: e.message, type: 'error' })
      })
    },
    onMove(e) {
      console.log('onmove',e);
      // 不允许拖动到最顶层
      if (e.draggedContext.element.itemType === 1) { return false }
      if (e.relatedContext.element.level && e.relatedContext.element.level === 1) { return false }
      // if(e.relatedContext){
      //   if(e.relatedContext.element.chapterId){
      //     return false
      //   }
      // }
    },
    handleExpansion(chapter, index) {
      // chapter.isShowExpansion  默认是假，显示向下的状态
      console.log(chapter);
      chapter.isShowExpansion = !chapter.isShowExpansion
      if (chapter.level === 1) {
        // 点击第一层，收起下面的全部
        if (chapter.isShowExpansion) {
          // 收起
          this.chapterDetailList.forEach((e, i) => {
            if (i !== 0) {
              e.isShow = false
            }
          })
        } else {
          // 展开
          this.chapterDetailList.forEach((e, i) => {
            if (i !== 0) {
              e.isShow = true
            }
          })
        }
      } else {
        // 如果不是第一层级，则收起改层面下面的
        for (let i = index + 1; i < this.chapterDetailList.length; i++) {
          if (this.chapterDetailList[i].itemType === 1 && chapter.level === this.chapterDetailList[i].level) {
            break
          } else {
            if (chapter.isShowExpansion) {
              this.chapterDetailList[i].isShow = false
            } else {
              this.chapterDetailList[i].isShow = true
            }
            this.$parent.loadMoreData()
          }
        }
      }
    },
    openChapterOption() {
      this.$emit('openChapterOption', this.batchSelectedCard, 'move')
    },
    addCardLeft(card, index) {
      this.$emit('openLorRCreateCard', card, index, 'left')
    },
    addCardRight(card, index) {
      this.$emit('openLorRCreateCard', card, index, 'right')
    },
    stopStudyCard(card) {
      let title = "";
      let status = ''
      let text = ''
      let msg = ''
      if (card.queue < 0) {
        title = "确定恢复卡片吗？";
        status = 1
        text = '恢复后，该卡片可以正常学习'
        msg = '恢复成功'
      } else {
        title = "确定停学卡片吗？";
        status = 2
        text = '暂停后，该卡片不会再安排复习，暂停后，可以再恢复'
        msg = '停学成功'
      }
      this.$confirm(text, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        distinguishCancelAndClose: true,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            request
              .request({
                url: "/card/suspendSwitch",
                method: "post",
                data: {
                  cardId: card.cardId,
                  status
                },
              })
              .then((data) => {
                card.queue = card.queue === -1 ? card.type : -1
                // card.status =
                showMessage({ message: msg, type: 'success' })
                done()
              })
              .catch((e) => {
                showMessage({ message: e.message, type: 'error' })
              });
          } else {
            done();
          }
        }
      })
    },
    handleMoreClick(menu, card, index) {
      if (menu.id === 1) {
        // 左加
        this.$emit('openLorRCreateCard', card, index, 'left')
      } else if (menu.id === 2) {
        // 右加
        this.$emit('openLorRCreateCard', card, index, 'right')
      } else if (menu.id === 3) {
        card.isShowMoreMenu = false
        this.$store.commit('setSelectAll', true)
      } else if (menu.id === 4) {
        this.$confirm('此操作将删除该卡片, 是否继续?', '提示', {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
        }).then(() => {
          request.request({
            url: '/card/delete',
            data: {
              cardId: card.cardId + ''
            },
            method: 'post'
          }).then(res => {
            this.chapterDetailList.splice(index, 1)
            showMessage({ message: '删除成功!', type: 'success' })
          }).catch(err => {
            showMessage({ message: err.message, type: 'error' })
          })
        })
      } else if (menu.id === 5) {
        this.stopStudyCard(card)
      }
    },
    handleChapterNameClick(chapter) {
      if (chapter.level === 1) return
      this.$router.push('/home/studyPlatform/chapter/' + chapter.chapterId)
    },
    // 新建卡牌盒子
    addNewChapter(chapter, index) {
      this.$prompt('', '新建卡牌盒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: '',
        inputPlaceholder: '请输入名称',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            const chapterId = new Date().getTime() + '001'
            request.request({
              url: '/chapter/save',
              method: 'post',
              data: {
                chapterId: chapterId,
                name: instance.inputValue,
                position: 0,
                parentId: chapter.chapterId
              }
            }).then((data) => {
              this.$store.dispatch('modifyChapterList')
              const chapterNode = {
                itemType: 1,
                chapterId: chapterId * 1,
                level: chapter.level + 1,
                name: instance.inputValue,
                isShow: true,
                isShowAdd: false,
                isShowExpansion: false,
                isShowFlag: false,
                isShowMenu: false,
                isShowMoreMenu: false
              }
              this.$emit('addNewChapter', chapter, index, chapterNode)
              showMessage({ message: '新增成功！', type: 'success' })
              done();
            }).catch((e) => {
              showMessage({ message: e.message, type: 'error' })
            })
          } else {
            done();
          }
        }
      });
    },
    onMenuClick(chapter, menu, index) {
      chapter.isShowMenu = false
      console.log(chapter);
      if (menu.id === 1) {
        this.$emit('openCreateCard', chapter)
      } else if (menu.id === 2) {
        this.addNewChapter(chapter, index)
      } else if (menu.id === 3) {
        if (chapter.level === 1) {
          return showMessage({ message: '一级目录不允许删除', type: 'error' })
        } else {
          this.$emit('deletecChapter', chapter, index)
        }
      }
    },
    // 最近添加的卡牌列表或者筛选出来的卡牌列表的预览
    toCardPreview(card, index, cardList) {
      this.$emit('toPreview', card, index, this.cardList)
    },
    editCard(card, index) {
      if (card.editable === 0) {
        return showMessage({ message: '此卡牌不能被编辑', type: 'error' })
      }
      this.$myLoading.start({ bgcColor: 'rgba(247, 247, 247, .9)' })
      request.request({
        url: '/card/detail',
        params: {
          cardId: card.cardId
        }
      }).then((res) => {
        this.$myLoading.finishProgress()
        this.$myLoading.finish()
        this.$emit('editorCard', res)
      }).catch(e => {
        showMessage({ message: e.message, type: 'error' })
      })
    },
    flagClick(card, flags) {
      request.request({
        url: '/card/flags',
        method: 'post',
        data: {
          cardId: card.cardId,
          flags
        }
      }).then(res => {
        card.flags = flags;
        card.isShowFlag = false
      }).catch(err => {
        showMessage({ message: err.message, type: 'error' })
      })
    },
    toEditorRecentCard(card, index) {
      this.$emit('toEditorRecentCard', card, index)
    },
    getCardDetail(card, index) {
      request.request({
        url: "/card/detail",
        params: {
          cardId: card.cardId
        }
      }).then(res => {
        this.$emit('toPreview', res, index)
      }).catch(err => {
        showMessage({ message: err.message, type: 'error' })
      })
    },
    onShowMoreMenu(chapter) {
      chapter.isShowMoreMenu = !chapter.isShowMoreMenu
    },
    handleCardClick(card, index) {
      if (this.$store.state.isSelectAll) {
        let indexOf = this.batchSelectedCard.indexOf(card.cardId);
        if (indexOf > -1) {
          this.batchSelectedCard.splice(indexOf, 1);
          this.selectCard.splice(indexOf, 1)
        } else {
          this.batchSelectedCard.push(card.cardId);
          this.selectCard.push(card)
        }
      } else {
        this.getCardDetail(card, index)
      }
    },
    //鼠标经过
    handleMouseenter(item) {
      item.isShowAdd = true
    },
    // 鼠标离开
    handleMouseleave(item) {
      item.isShowAdd = false
      item.isShowMoreMenu = false
    },
    onKeyupEnter(e) {
      e.preventDefault();
    },
    editorNewChapter: debounce(function (chapter) {
      if (chapter.name.trim().length === 0) return
      request.request({
        url: '/chapter/save',
        data: {
          chapterId: chapter.chapterId,
          parentId: chapter.parentId,
          name: chapter.name,
          position: chapter.position
        }, method: 'post'
      }).then(res => {
        console.log(res);
      }).catch(e => {
        showMessage({ message: e.message, type: 'error' })
      })
    }, 500, false),
  },
  computed: {
    getShowColumn() {
      return (n) => {
        return ((this.myChapterWidth - (12 * n)) / n + 'px')
      }
    },
    showOperateText() {
      return () => {
        if (this.selectCard.some(v => v.queue !== -1)) {
          return '停学'
        } else {
          return "恢复"
        }
      }
    },
    isActive() {
      return (card, index) => {
        if (this.$store.state.isSelectAll) {
          return this.batchSelectedCard.indexOf(card.cardId) > -1 ? 'active' : ''
        } else {
          return this.currentIndex === index ? 'active' : '';
        }
      }
    },
    adjustMarginLeft() {
      return level => {
        if (level === 1) return '0px'
        if (level > 1) {
          return (level - 1) * 12 + 'px'
        }
      }
    },
    fetchCardBgc() {
      return (id) => {
        switch (id) {
          case 0:
            return '#fff'
          case 1:
            return '#FF4B3E'
          case 2:
            return '#FFA800'
          case 3:
            return '#1789FC'
        }
      }
    },
    fetchCardBgc1() {
      return (id) => {
        switch (id) {
          case 0:
            return '#fff'
          case 1:
            return 'rgba(255, 75, 62, .16)'
          case 2:
            return 'rgba(255, 214, 10, .16)'
          case 3:
            return 'rgba(23, 137, 252, .16)'
        }
      }
    },
    modifyTime() {
      return (time) => {
        return dayjs(time).format('MM.DD HH:mm')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/mixin.scss';

.chapter-detail {
  padding-bottom: 150px;
  margin-top: 14px;


  .clear {
    clear: both;
  }

  .recent-add {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon-box {
        position: relative;
        display: flex;
        align-items: center;

        .addMenu {
          position: absolute;
          left: -8px;
          z-index: 999;
        }

        .text {
          color: #000000;
          font-weight: 500;
          font-size: 16px;
        }
      }

      .right {
        display: flex;
        align-items: center;

        .num {
          font-size: 14px;
          color: #000000;
          opacity: .4;
        }
      }
    }
  }

  .chapter-detail-item {

    .chapter-detail-item-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      min-height: 48px;

      .chapter-detail-item-name {
        display: flex;
        align-items: center;
        width: 100%;

        .chapter-detail-item-title {
          width: 100%;
        }

        .icon-box {
          position: relative;
          display: flex;
          align-items: center;

          // height: max-content;
          .addMenu {
            position: absolute;
            left: -8px;
            z-index: 999;
          }

          .my-add-menu-box {
            position: absolute;
            top: 30px;
            left: 0;
            background-color: #fff;
            border-radius: 8px;
            box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            width: 180px;
            z-index: 9999;

            .add-menu-item {
              height: 48px;
              display: flex;
              align-items: center;
              color: #202020;
              padding: 0 16px;

              .menu-name {
                margin-left: 14px;
                font-size: 14px;
              }

              &:hover {
                cursor: pointer;
                background-color: #f7f7f7;
              }
            }
          }
        }
      }

      .chapter-detail-item-cardnum {
        display: flex;
        align-items: center;

        .cardnum {
          font-size: 14px;
          color: #000000;
          opacity: .4;
        }
      }
    }
  }

  .card:hover .card-mod {
    display: block;
  }


  .card:hover .edit-box {
    display: block;
  }

  .card:hover .flag-box {
    display: block;
  }

  .card:hover .more-menu-box {
    display: block;
  }

  .flag-box:hover .flag-menu-container {
    display: block;
  }

  .card {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    float: left;
    height: 249px;
    padding: 16px;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.03);
    margin: 0 12px 12px 0;
    transition: all .3s, -webkit-transform .3s;

    .card-mod {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 11;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      display: none;
    }

    &.active {
      box-shadow: 0 0 0 4px rgba(255, 183, 0, 0.5);
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
      z-index: 9 !important;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    }

    .menu-box {
      position: absolute;
      width: 34px;
      height: 34px;
      text-align: center;
      line-height: 34px;
      background: #FFFFFF;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
      z-index: 99;
      opacity: .97;

      &:hover {
        opacity: 1;
      }
    }

    .edit-box {
      top: 10px;
      left: 10px;
      display: none;
    }

    .flag-menu-container {
      position: absolute;
      top: 0px;
      right: 30px;
      display: none;
      z-index: 99;
      padding-right: 10px;

      .flag-menu-box {
        padding: 0 9px;
        background-color: #fff;
        border-radius: 8px;
        height: 34px;
        display: flex;
        align-items: center;
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);

        .flag-menu-item {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;

          .item {
            width: 16px;
            height: 16px;
            border-radius: 50%;
          }

          .item1 {

            background: #FFA800 !important;
          }

          .item2 {
            background: #FF4B3E;
          }

          .item3 {
            background: #1789FC;
          }

          &:hover {
            background-color: #ccc;
          }
        }
      }
    }

    .flag-box {
      top: 10px;
      right: 10px;
      display: none;

      .flag-icon-box {
        width: 34px;
        height: 34px;
        text-align: center;
        line-height: 34px;
        border-radius: 8px;
      }
    }

    .more-menu-box {
      bottom: 10px;
      right: 10px;
      display: none;

      .more-menu {
        position: absolute;
        bottom: 35px;
        right: 0;
        background-color: #fff;
        width: 150px;
        box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        overflow: hidden;
        z-index: 999999;

        .more-menu-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 40px;
          padding: 0 16px;
          font-size: 14;
          color: #202020;

          &:hover {
            background-color: #f7f7f7;
            cursor: pointer;
          }
        }
      }
    }

    .card-content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .card-title {
        font-size: 15px;
        font-weight: 500;
        color: #000000;
        margin-bottom: 8px;
        @include clamp(2);
      }

      .card-detail {
        font-size: 14px;
        line-height: 24px;
        color: rgba(32, 32, 32, 0.6);

        &.clip1 {
          @include clamp(1)
        }

        &.clip5 {
          @include clamp(6)
        }
      }

      .card-img {
        width: 100%;
        height: 112px;
        border-radius: 6px;
        margin: 5px 0 10px;
        object-fit: cover;
      }
    }

    .card-label-container {
      display: flex;
      justify-content: space-between;

      .card-type {
        display: flex;
        align-items: center;

        .card-type-item {
          display: flex;
          align-items: center;
          justify-content: center;

          .card-type-text {
            color: rgba(32, 32, 32, 0.4);
            font-size: 12px;
            transform: scale(0.9);
            line-height: 14px;
          }
        }
      }

      .card-created-time {
        color: #202020;
        font-size: 12px;
        transform: scale(0.9);
        opacity: .6;
      }
    }
  }
}

::v-deep .el-textarea__inner {
  width: 100%;
  border: none;
  resize: none;
  padding: 0;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  background-color: #f7f7f7;

  &:hover {
    cursor: pointer;
  }
}
</style>
