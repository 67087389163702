<template>
  <!-- 设置学习目标 -->
  <div class="plan-degree">
    <div class="plan-item" v-for="(item, index) in goalList" :key="item.id" @click="goalClick(item, index)"
      :class="planMemoObj1 === item.id ||currentIndex === index ? 'active' : ''">
      <div class="content">
        <div class="title">{{ item.title }}</div>
        <div class="desc">{{ item.desc }}</div>
      </div>
      <img src="../../assets/images/icons_filled_toggle_PC_light_tick-circle_24pt@2x.png" class="degree-img"
        v-if="planMemoObj1 === item.id ||currentIndex === index" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    planMemoScene: {
      type: Number
    },
    planMemoObj:{
      type:Number
    },
    ifFirst:{
      type:Boolean,
      default: false
    }
  },
  data() {
    return {
      planMemoObj1:'',
      currentIndex: '',
      goalList: [
        {
          title: '简单了解',
          desc: '对内容有初步认识，了解大概内容即可',
          id: 1
        },
        {
          title: '初步掌握',
          desc: '对内容有进一步的理解，掌握知识点脉络',
          id: 2
        }, {
          title: '熟练运用',
          desc: '对内容深刻理解，能运用知识解决相关问题',
          id: 3
        },
      ],
    }
  },
  methods: {
    goalClick(item, index) {
      this.currentIndex = index
      this.planMemoObj1 = item.id
      this.$emit('selectGoal', item.id)
    }
  },
  mounted() {
    if(this.ifFirst) {
      this.planMemoObj1 = this.planMemoScene === 1?2:3
    }else {
      this.planMemoObj1 = this.planMemoObj
    }
  },
}
</script>

<style lang="scss" scoped>

.plan-desc {
  margin-bottom: 20px;
  color: rgba(32, 32, 32, 0.6);
}

.plan-degree {
  .plan-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid rgba(32, 32, 32, 0.16);
    margin-bottom: 16px;

    &.active {
      border: 2px solid #FFA800;
      ;
    }

    &:hover {
      cursor: pointer;
    }

    .content {
      display: flex;
      align-items: center;

      .title {
        font-size: 14px;
        margin-right: 8px;
        border-radius: 6px;
        color: #202020;
      }

      .desc {
        font-size: 12px;
        color: rgba(32, 32, 32, 0.4);
      }
    }

    .degree-img {
      width: 20px;
      height: 20px;
    }
  }
}
</style>