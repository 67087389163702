<template>
  <div class="chapter-header">
    <img :src="chapter.image" class="chapter-img" v-if="chapter.image ">
    <div class="chapter-content">
      <div class="chapter-title">
        {{ chapter.name}}
      </div>
      <div class="chapter-study-data">
        <div class="chapter-study-data-text">新卡 {{chapter.newCardNum[0]}}</div>
        <div class="chapter-study-data-line"></div>
        <div class="chapter-study-data-text">复习 {{chapter.revCardNum[0]}}</div>
        <div class="chapter-study-data-line"></div>
        <div class="chapter-study-data-text" v-if="chapter.studyTime !== 0">学时 {{formatTime(chapter.studyTime)}}</div>
      </div>
      <div class="chapter-progress">
        <div class="display-flex align-items-center">
          <div class="progress">
            <el-progress v-if="chapter.status === 1" :show-text="false" class="my-progress" type="circle"
              :color="chapter.cardNum > 0 && chapter.newCardNum[1] === 0 ? '#FF8800' : '#FFB700'" :stroke-width="2"
              :width="18"
              :percentage="chapter.cardNum > 0 && chapter.newCardNum[1] === 0 ? hanldeCompleteNum : studyCompleteNum">
            </el-progress>
            <el-progress v-else :show-text="false" color="rgba(123, 123, 128, 0.12)" :stroke-width="2" :width="18"
              type="circle" class="my-progress"
              :percentage="chapter.cardNum > 0 && chapter.newCardNum[1] === 0 ? hanldeCompleteNum : studyCompleteNum">
            </el-progress>
            <div class="progress-text" :style="{color:'#FF8800'}" v-if="chapter.cardNum>0&& chapter.newCardNum[1]=== 0">
              已掌握{{chapter.handledCardNum}}/{{ chapter.cardNum }}
            </div>
            <div v-else class="progress-text" :style="{color:'#FFB700'}">已学{{ chapter.cardNum - chapter.newCardNum[1]
            }}/{{ chapter.cardNum }}</div>
          </div>
          <div class="progress-text mg-l-0 fs-14  align-items-center" v-if="chapter.planMemoTime.indexOf('-') > -1">
            <div class="chapter-study-data-line"></div><svg-icon iconClass="card_time_deadline" class="w-18 h-18 mg-r-6"></svg-icon>距考试 {{surplusDay}} 天</div>
        </div>
        <el-button class="w-130 h-40 bdr-8" type="primary" @click="beginStudyClick"
          :disabled="chapter.status !== 1 || chapter.newCardNum[1] + chapter.revCardNum[1] + chapter.revCardNum[2] === 0"
          :style="{ background: chapter.status !== 1 || chapter.newCardNum[1] + chapter.revCardNum[1] + chapter.revCardNum[2] === 0 ? '#ccc' : '', borderColor: chapter.status !== 1 || chapter.newCardNum[1] + chapter.revCardNum[1] + chapter.revCardNum[2] === 0 ? '#ccc' : '' }">
          {{showBtnText()}}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from '../../libs/analyse'
import dayjs from 'dayjs'
export default {
  props: {
    chapter: {
      type: Object,
      default: () => { }
    }
  },
  methods: {
    beginStudyClick() {
      this.$emit('beginStudyClick')
    }
  },
  computed: {
    surplusDay() {
      // 剩余天数
      let now = dayjs(dayjs((new Date).getTime()).format('YYYY-MM-DD'))
      if (isNaN(dayjs(this.chapter.planMemoTime).diff(now, 'hour'))) {
        return ''
      }
      if (dayjs(this.chapter.planMemoTime).diff(now, 'hour') <= 0) {
        return '0'
      } else {
        return dayjs(this.chapter.planMemoTime).diff(now, 'hour') / 24
      }
    },
    formatTime() {
      return time => {
        return formatTime(time)
      }
    },
    showBtnText() {
      return () => {
        if (this.chapter.newCardNum[0] + this.chapter.revCardNum[0] > 0) {
          return '待记忆' + (this.chapter.newCardNum[0] + this.chapter.revCardNum[0])
        } else {
          return '增加学习量'
        }
      }
    },
    // 已完成的学习量百分比
    studyCompleteNum() {
      return this.chapter.cardNum > 0 ? Number(((this.chapter.cardNum - this.chapter.newCardNum[1]) / this.chapter.cardNum * 100).toFixed(1)) : 0
    },
    // 已经掌握的百分比量
    hanldeCompleteNum() {
      return this.chapter.cardNum > 0 ? Number((this.chapter.handledCardNum / this.chapter.cardNum * 100).toFixed(1)) : 0
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/css/mixin.scss';

.chapter-header {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  background-color: #fff;
  padding: 16px;
  display: flex;
  height: 166px;

  .chapter-img {
    width: 100px;
    height: 134px;
    border-radius: 6px;
    object-fit: cover;
  }

  .chapter-content {
    flex: 1;
    margin-left: 16px;

    .chapter-title {
      font-size: 20px;
      font-weight: 500;
      color: #000000;
      line-height: 22px;
      margin-top: 5px;
      @include clamp(1)
    }

    .chapter-study-data {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .chapter-study-data-text {
        font-size: 14px;
        font-weight: 500;
        color: #202020;
        line-height: 20px;
      }

    }

    .chapter-progress {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;

      .progress {
        display: flex;
        align-items: center;
      }

      .progress-text {
        font-size: 14px;
        font-weight: 500;
        color: #FFb700;
        margin-left: 6px;
      }
    }
  }
}

.chapter-study-data-line {
  width: 1px;
  height: 14px;
  background-color: rgba(32, 32, 32, 0.3);
  opacity: .4;
  border-radius: 1px;
  margin: 0 10px;
}
</style>

<style>
.my-progress .el-progress-circle__track {
  stroke: rgba(255, 183, 0, 0.3);
}
</style>
