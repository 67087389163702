<template>
  <el-dialog title="分享卡牌至商店" :visible.sync="showShareChapter" :close-on-click-modal="false" width="475px"
             :destroy-on-close="true" @open="onOpen">
    <div class="share-chapter-container">
      <div class="share-type">
        <div v-for="(item, index) in btnList" :key="item.id" class="share-btn"
             :class="currentIndex === index ? 'active' : ''" @click="typeClick(item, index)">
          <i class="el-icon-check chekcIcon" v-if="currentIndex === index"></i>
          {{ item.text }}
        </div>
      </div>
      <div class="share-content-box" v-if="currentIndex === 0 && publishChapterDetailInfo == null">
        <div class="share-title">分享需完善卡片以下信息:</div>
        <el-select style="margin-bottom: 10px" v-model="submitData.level" placeholder="选择卡片所属学习阶段"
                   class="w-fill">
          <el-option el-option v-for="item in levelList" :key="item.level" :label="item.name" :value="item.level">
          </el-option>
        </el-select>
        <el-select v-model="submitData.cate" multiple filterable remote allow-create
                   placeholder="选择或输入学科专业 最多3个"
                   :multiple-limit="3" :remote-method="getRemoteTag" :loading="loading" class="w-fill">
          <el-option v-for="item in cateList" :label="item" :value="item" :key="item"></el-option>
        </el-select>
      </div>

      <div class="pay-share-content mg-b-50"
           v-if="currentIndex === 1 && publishChapterDetailInfo == null || (currentIndex === 1 && publishChapterDetailInfo !== null && publishChapterDetailInfo.price === 0)">
        <div class="img">
          <img src="../../assets/images/myloaidngimg@2x.png" alt="" class="w-24 h-24">
        </div>
        <div class="text">付费分享需前往发布页完成卡片信</div>
        <div class="text mg-t-0">息、定价等内容</div>
      </div>
      <!-- v-if="publishChapterDetailInfo !== null && publishChapterDetailInfo !== undefined" -->
      <div class="share-content-edit" v-if="showEditor()">
        <div class="sharing">
          <div class="text">分享中</div>
          <el-switch v-model="publishChapterDetailInfo.status" inactive-color="#A6A6A6" active-color="#FFB700"
                     :active-value="1" :inactive-value="2" @change="handleChangeStatus">
          </el-switch>
        </div>
        <div class="sharing">
          <div class="text">允许用户编辑</div>
          <el-switch v-model="publishChapterDetailInfo.allowEdit" inactive-color="#A6A6A6" active-color="#FFB700"
                     :active-value="1" :inactive-value="0" @change="handleChangeEditalbe">
          </el-switch>
        </div>
        <div class="sharing mg-b-20" @click="toPublish">
          <div class="text">编辑更多</div>
          <i class="el-icon-arrow-right moreIcon"></i>
        </div>
      </div>
      <!-- <div class="agreement" v-if="currentIndex === 0 || (publishChapterDetailInfo !== null && currentIndex === 1 && publishChapterDetailInfo.price !== 0)">
        <div class="agree"><img src="../../assets/images/icons_filled_toggle_PC_light_tick-circle_24pt@2x.png"
            class="mg-r-4">我已阅读并同意<span>《服务条款》</span>和 <span>《隐私协议》</span></div>
        <div class="desc">Tips: 作为卡片的分享者，你需要对文档的合规合法性负责</div>
      </div> -->
      <div class="h-1 bg-e8e"></div>
      <div class="mg-t-15"
           v-if="publishChapterDetailInfo && publishChapterDetailInfo.dataVer && publishChapterDetailInfo.dataVer > publishChapterDetailInfo.ver">
        <div class="fs-16 text-strong c-2 mg-b-10">卡牌盒数据</div>
        <div>学习台最近一次修改：{{ dateFormat(publishChapterDetailInfo.dataVer, 'YYYY-MM-DD HH:mm:ss') }}</div>
        <div>商店最近一次更新于：{{ dateFormat(publishChapterDetailInfo.ver, 'YYYY-MM-DD HH:mm:ss') }}</div>
      </div>
      <div class="display-flex justify-content-center mg-t-20">
        <el-button class="w-fill h-50" @click="handleSubmitShare">{{ showBtnText() }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import request from '../../libs/api.request'
import {showMessage} from '../../libs/message'
import {copyData} from '../../libs/myClipboard'
import dayjs from 'dayjs'

export default {
  props: {
    chapter: {
      type: Object,
      default: () => {
      }
    },
    publishChapterDetailInfo: {
      default: () => {
      }
    },
  },
  data() {
    return {
      showShareChapter: false,
      levelList: [
        {name: '语言', level: 1},
        {name: '考研', level: 2},
        {name: '公考', level: 5},
        {name: '考证', level: 3},
        {name: '大学', level: 6},
        {name: '专升本', level: 4},
        {name: '高中', level: 7},
        {name: '初中', level: 8},
        {name: '小学', level: 9},
        {name: '学前', level: 10},
        {name: '其他', level: 11},
      ],
      btnList: [
        {text: '免费分享', id: 1},
        {text: '付费分享', id: 2},
      ],
      currentIndex: 0,
      submitData: {
        price: 1,
        cate: '',
        level: '',
        introduction: '',
        title: '',
      },
      cateList: [],
      checked: false,
      loading: false,
    }
  },
  methods: {
    dateFormat(time, format) {
      if (time < 100000000000) {
        time *= 1000
      }
      return dayjs(time).format(format)
    },
    handleSubmitShare() {
      let btnType = this.btnType();
      if (btnType === 4) {
        let loading = this.$loading({
          lock: true,
          text: '正在更新，请稍等！',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.5)'
        });
        request.request({
          url: '/shop/chapter/updateData',
          method: 'post',
          data: {
            chapterId: this.chapter.chapterId,
          }
        }).then(res => {
          loading.close();
          showMessage({message: '更新成功', type: 'success'})
          setTimeout(() => {
            location.reload();
          }, 600);
        }).catch(e => {
          loading.close();
          showMessage({message: e.message, type: 'error'})
        })
      } else if (btnType === 3) {
        let copyUrl = "https://store.amazingmemo.com/chapterDetail/" + this.$route.params.chapterId
        copyData(copyUrl)
        return
      } else if (btnType === 1 && this.publishChapterDetailInfo !== null) {
        this.handleChangeStatus(1)
        return
      }
      // 已经分享过了
      if (this.publishChapterDetailInfo !== null) {
        if (this.currentIndex === 1 && this.publishChapterDetailInfo.price === 0) {
          //付费分享里面点击按钮
          this.toPublish()
          return
        }
      } else {
        // 为开启分享
        if (this.currentIndex === 1) {
          this.toPublish()
        } else {
          if (this.submitData.level === '') return showMessage({message: '请选择学习阶段', type: 'error'})
          if (this.submitData.cate.length === 0) return showMessage({
            message: '至少选择一项学科专业分类',
            type: 'error'
          })
          request.request({
            url: '/shop/chapter/publish',
            method: 'post',
            data: {
              chapterId: this.$route.params.chapterId,
              cate: JSON.stringify(this.submitData.cate),
              level: this.submitData.level,
              price: 0,
              allowEdit: 1,
              title: this.chapter.name
            }
          }).then(res => {
            // this.publishChapterDetailInfo
            showMessage({message: '分享成功', type: 'success'})
            this.$parent.getChapterInfo()
          }).catch(e => {
            showMessage({message: e.message, type: 'error'})
          })
        }
      }
    },
    handleChangeStatus(status) {
      request.request({
        url: '/shop/chapter/changePubStatus',
        method: 'post',
        data: {
          chapterId: this.$route.params.chapterId,
          status
        }
      }).then(res => {
        this.publishChapterDetailInfo.status = status
        if (status === 1) {
          showMessage({message: '分享成功', type: 'success'})
        } else {
          showMessage({message: '已取消分享', type: 'warning'})
        }
      }).catch(e => {
        showMessage({message: e.message, type: 'error'})
      })
    },
    handleChangeEditalbe(allowEdit) {
      request.request({
        url: '/shop/chapter/changeEditable',
        method: 'post',
        data: {
          chapterId: this.$route.params.chapterId,
          allowEdit
        }
      }).then(res => {
      }).catch(e => {
        showMessage({message: e.message, type: 'error'})
      })
    },
    toPublish() {
      this.$emit('openPublish')
    },
    typeClick(item, index) {
      if (this.currentIndex === index) return
      if (this.publishChapterDetailInfo !== null && this.publishChapterDetailInfo.price > 0) {
        if (index === 0) {
          this.$confirm('切换为免费后，该卡牌盒在商店可免费使用', '确定免费分享吗', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                request.request({
                  url: '/shop/chapter/changeToFree',
                  method: 'post',
                  data: {chapterId: this.$route.params.chapterId,}
                }).then((res) => {
                  showMessage({message: '免费分享成功！', type: 'success'})
                  this.currentIndex = index
                  this.$parent.getChapterInfo()
                  done();
                }).catch((e) => {
                  showMessage({message: e.message, type: 'error'})
                })
              } else {
                done();
              }
            }
          })
        }
      } else {
        this.currentIndex = index
      }
    },
    getRemoteTag(keyword) {
      if (keyword !== '') {
        this.loading = true;
        request.request({
          url: '/shop/index/searchCate',
          params: {
            keyword: keyword
          }
        }).then(res => {
          this.loading = false;
          this.cateList = res.list;
        }).catch(e => {
          this.loading = false;
          this.cateList = [];
        });
      }
    },
    open() {
      this.showShareChapter = true
    },
    close() {
      this.showShareChapter = false
    },
    btnType() {
      if (this.publishChapterDetailInfo === null) {
        if (this.currentIndex === 0) {
          return 1
        } else {
          return 2
        }
      } else {
        if (this.publishChapterDetailInfo.status === 2) {
          return 1
        }
        if (this.currentIndex === 0 && this.publishChapterDetailInfo.status === 1) {
          if (this.publishChapterDetailInfo.dataVer > this.publishChapterDetailInfo.ver) {
            return 4
          }
          return 3
        }
        if (this.currentIndex === 1 && this.publishChapterDetailInfo.price === 0) {
          return 2
        } else {
          if (this.publishChapterDetailInfo.dataVer > this.publishChapterDetailInfo.ver) {
            return 4
          }
          return 3
        }
      }
    },
    showBtnText() {
      let btnType = this.btnType();
      console.log(btnType);
      switch (btnType) {
        case 1:
          return '开启分享';
        case 2:
          return '前往发布';
        case 3:
          return '复制链接';
        case 4:
          return '更新至商店';
      }
    },
    onOpen() {
      console.log("onOpen");
      if (this.publishChapterDetailInfo && this.publishChapterDetailInfo.chapterId) {
        request.request({
          url: '/shop/chapter/pubInfo',
          params: {
            chapterId: this.publishChapterDetailInfo.chapterId,
          }
        }).then(res => {
          this.publishChapterDetailInfo = res;
        })
      }
    }
  },
  computed: {
    showEditor() {
      return () => {
        if (this.publishChapterDetailInfo !== null) {
          if (this.currentIndex === 0) {
            return true
          } else {
            if (this.publishChapterDetailInfo.price > 0) {
              return true
            } else {
              return false
            }
          }
        } else {
          return false
        }
      }
    }
  },
  watch: {
    publishChapterDetailInfo(newDetail) {
      if (newDetail !== null) {
        if (newDetail.price > 0) {
          this.currentIndex = 1
        } else {
          this.currentIndex = 0
        }
      }
    }
  },
  created() {
    if (this.publishChapterDetailInfo !== null && this.publishChapterDetailInfo.price > 0) {
      this.currentIndex = 1
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

::v-deep .el-select .el-tag {
  margin-left: 24px;
  font-size: 14px;
}

.share-chapter-container {
  .share-title {
    font-weight: 600;
    color: #202020;
    font-size: 16px;
    margin-bottom: 16px;
  }

  .share-type {
    display: flex;
    justify-content: space-between;

    .share-btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 203px;
      color: #202020;
      height: 48px;
      background-color: #fff;
      border-radius: 8px;
      font-weight: 600;
      border: 1px solid rgba(60, 60, 67, 0.29);

      &:hover {
        cursor: pointer;
      }

      .chekcIcon {
        position: absolute;
        left: 30px;
        font-size: 20px;
        color: #fff;
      }

      &.active {
        color: #fff;
        background: #FFB700;
        border: none;
      }
    }
  }

  .share-content-box {
    margin-top: 30px;

    .share-title {
      font-weight: 600;
      color: #202020;
      font-size: 16px;
      margin-bottom: 16px;
    }

  }

  .share-content-edit {
    .sharing {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;

      .text {
        font-size: 16px;
        font-weight: 500;
        color: #202020;
      }

      .moreIcon {
        &:hover {
          cursor: pointer;
          color: #FFB700;
        }
      }
    }
  }

  .pay-share-content {
    margin-top: 73px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 54px;
      height: 68px;
      background: #FFFFFF;
      box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
    }

    .text {
      margin-top: 14px;
      font-size: 14px;
      color: #202020;
      font-weight: 500;
    }
  }

  .agreement {
    margin-top: 16px;

    .agree {

      display: flex;
      align-items: center;
      color: rgba(32, 32, 32, 0.4);
      font-size: 12px;

      span {
        color: #FFB700
      }

      img {
        width: 16px;
        height: 16px;
      }
    }

    .desc {
      margin-top: 8px;
      font-size: 12px;
      color: #202020;
      line-height: 18px;
      opacity: .4;
    }
  }

  .share-btn {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    color: #fff;
    background: #FFB700;
  }
}

::v-deep .el-dialog__title {
  font-size: 20px;
}

::v-deep .el-select {
  width: 100%;
  border-radius: 12px;
}

::v-deep .el-input__inner {
  border-radius: 8px;
  height: 48px;
}

::v-deep .el-dialog__body {
  padding-bottom: 30px !important;
}

::v-deep .el-dialog__header {
  padding: 24px;
}
</style>
