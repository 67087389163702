<template>
  <div class="position-relative">
    <!-- <div id="toolbar-container"></div> -->
    <div id="myCkEditor"></div>
  </div>
</template>

<script>
import request from '../../libs/api.request'
  export default {
    props: {
      commodityDesc: {
        type: String,
        default: ''
      }
    },
    methods: {
      initEditor() {
        console.log('initeditor');
        AnkiEditor.create(document.querySelector('#myCkEditor'), {
          placeholder: "简要描述商品文案",
          simpleUpload: {
            uploadUrl: request.baseUrl + '/common/editorUploadImg',
            headers: {Authorization: this.$cookies.get('authToken')}
          },
          toolbar: {
          items: [
            'findAndReplace', 'selectAll', '|',
            'heading', '|',
            'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', 'removeFormat', '|',
            'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
            'bulletedList', 'numberedList', 'todoList', '|',
            'outdent', 'indent', '|',
            'undo', 'redo',
            '-',
            'alignment', '|',
            'link', 'insertImage', 'ImageUpload', 'blockQuote', 'insertTable', 'mediaEmbed', 'codeBlock', 'htmlEmbed', '|',
            'specialCharacters', 'horizontalLine', 'pageBreak', '|',
            'textPartLanguage', '|',
            'sourceEditing',
          ],
        },
          // toolbar: ['bold','fontColor','bulletedList','ImageUpload','link']
        }).then(editor => {
          if (this.commodityDesc.trim().length > 0) {
            editor.setData(this.commodityDesc)
          }
          editor.model.document.on('change', () => {
            this.$emit('editorChange', editor.getData())
          })
        }).catch(error => {
          console.error(error);
        });
      },
    },
    mounted() {
      this.initEditor()
    }
  }
</script>

<style lang="scss" scoped>
::v-deep .ck-editor {
  border-radius: 8px;
  border: 1px solid rgba(60, 60, 67, 0.29);
}
  ::v-deep .ck .ck-button .ck-disabled .ck-off {
    display: none;
  }

  ::v-deep .ck .ck-button .ck-disabled .ck-off {
    display: none;
  }

::v-deep .ck-placeholder {
  font-size:14px;
}
::v-deep .ck-editor__main {
  position: relative;
  font-size: 14px;
  color: #202020;
}
::v-deep .ck-content {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  padding: 0 24px;
  min-height: 430px;
  font-size: 16px;
}
// ::v-deep .ck .ck-toolbar .ck-toolbar_grouping {
//   padding: 15px 24px !important;
// }
::v-deep .ck-toolbar_grouping>.ck-toolbar__items {
  padding: 15px 15px 0px !important;
}
::v-deep .ck-balloon-rotator__content {
  z-index: 9999;
}
</style>
