<template>
  <div class="study-preference">
    <div class="set-item">
      <div class="set-option">记忆场景</div>
      <div class="set-value" @click="() => { if (!isFirst) { showMemoScene = true } }">
        <div>{{ planMemoScene === 1 ? '长期记忆' : '准备考试' }}</div>
        <svg-icon iconClass="arrow_right" class="w-16 h-16 cursor-pointer" v-if="!isFirst"></svg-icon>
      </div>
    </div>
    <div class="set-item">
      <div class="set-option">学习目标</div>
      <div class="set-value" @click="() => { if (!isFirst) { showSelectGoal = true } }">
        <div>{{ studyGoal(planMemoObj) }}</div>
        <svg-icon iconClass="arrow_right" class="w-16 h-16 cursor-pointer" v-if="!isFirst"></svg-icon>
      </div>
    </div>

    <!-- <div class="set-item">
      <div class="set-option">安排模式</div>
      <div class="set-value"></div>
    </div> -->
    <div class="set-item">
      <div class="set-option">{{ planMemoScene === 2 ? '考试日期' : '每日学时（分）' }}</div>
      <div class="set-value" @click="onSetMemoTime">
        <div>{{ planMemoTime.length > 0 ? planMemoTime : '未设置' }}</div>
        <svg-icon iconClass="arrow_right" class="w-16 h-16 cursor-pointer" v-if="!isFirst"></svg-icon>
      </div>
    </div>
    <div class="set-item">
      <div class="set-option">每日新卡上限</div>
      <div class="set-value">
        <el-input v-model="planNewCardNum" type="number"></el-input>张
      </div>
    </div>
    <div class="set-item">
      <div class="set-option">每日复习卡上限</div>
      <div class="set-value">
        <el-input v-model="planRevCardNum" type="number"></el-input>张
      </div>
    </div>
    <div class="set-item">
      <div class="set-option">记忆强度</div>
      <div class="set-value" @click="showSelectStrength = true">
        <div>{{ memoStrength(lrnLevel) }}</div>
        <svg-icon iconClass="arrow_right" class="w-16 h-16 cursor-pointer"></svg-icon>
      </div>
    </div>
    <!-- <div class="set-item">
      <div class="set-option">预计耗时（分）</div>
      <div class="set-value">
        <div>长期记忆</div>
        <svg-icon iconClass="arrow_right" class="w-16 h-16"></svg-icon>
      </div>
    </div> -->
    <div class="study-set-item">
      <div class="set-option">学习顺序</div>
      <div class="set-value">
        <el-radio-group v-model="planStudyOrder">
          <el-radio :label="1">新卡优先</el-radio>
          <el-radio :label="2">复习卡优先</el-radio>
          <el-radio :label="3">随机顺序</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="set-item">
      <div class="set-option">新卡顺序</div>
      <div class="set-value">
        <el-radio-group v-model="planNewOrder">
          <el-radio :label="1" style="margin-right:71px">添加顺序</el-radio>
          <el-radio :label="2">随机顺序</el-radio>
        </el-radio-group>
      </div>
    </div>
    <el-dialog :title="title1" :visible.sync="showMemoScene" width="408px" :destroy-on-close="true" append-to-body
      v-if="showMemoScene">
      <SetStudyScene :planMemoScene="planMemoScene" @selectScene="selectScene" v-show="step === 1"></SetStudyScene>
      <SetStudyPlan :planMemoScene="planMemoScene" v-show="step === 2" @setPlanMemoTime="setPlanMemoTime"
        @setPlanNewCardNum="setPlanNewCardNum">
      </SetStudyPlan>
      <div class="next-btn">
        <el-button @click="onPrevStepClick" v-if="step !== 1">上一步</el-button>
        <el-button type="primary" @click="onNextStepClick" class="step-btn">{{ step === 2 ? '完成' : '下一步' }}</el-button>
      </div>
    </el-dialog>
    <el-dialog title="选择记忆强度" :visible.sync="showSelectStrength" width="408px" :destroy-on-close="true" append-to-body>
      <SetStudyStrength @selectStrength="selectStrength" :planLrnLevel="lrnLevel"></SetStudyStrength>
    </el-dialog>
    <el-dialog title="选择学习目标" :visible.sync="showSelectGoal" width="420px" :destroy-on-close="true" append-to-body>
      <SetStudyGoal :planMemoObj="planMemoObj" @selectGoal="selectGoal"></SetStudyGoal>
    </el-dialog>
    <el-dialog :title="planMemoScene === 1 ? '设置每天学习量' : '选择考试日期'" :visible.sync="showSelectStudyTime" width="408px"
      append-to-body :before-close="()=> { isShowSetTime = false;showSelectStudyTime = false}">
      <SetStudyTime @SetStudyTime="SetStudyTime" :chapter="chapter" v-show="isShowSetTime"></SetStudyTime>
      <SetStudyDate :chapter="chapter" @SetStudyTime="SetStudyTime" v-show="planMemoScene === 2"></SetStudyDate>
    </el-dialog>
  </div>
</template>
<script>
import request from '../../libs/api.request'
import { showMessage } from '../../libs/message'

import SetStudyGoal from './SetStudyGoal.vue'
import SetStudyScene from './SetStudyScene.vue'
import SetStudyStrength from './SetStudyStrength.vue'
import SetStudyPlan from './SetStudyPlan.vue'
import SetStudyTime from './SetStudyTime.vue'
import SetStudyNum from './SetStudyNum.vue'
import SetStudyDate from './SetStudyDate.vue'

export default {
  props: {
    chapter: {
      type: Object,
      default: () => { }
    },
    isFirst: {
      tyep: Boolean,
      default: false
    }
  },
  components: {
    SetStudyPlan,
    SetStudyStrength,
    SetStudyScene,
    SetStudyGoal,
    SetStudyTime,
    SetStudyNum,
    SetStudyDate
  },
  data() {
    return {
      planMemoScene: this.chapter.planMemoScene,
      lrnLevel: this.chapter.planLrnLevel,
      planNewCardNum: this.chapter.planNewCardNum,
      planRevCardNum: this.chapter.planRevCardNum,
      planNewOrder: this.chapter.planNewOrder,
      planStudyOrder: this.chapter.planStudyOrder,
      planMemoObj: this.chapter.planMemoObj,
      planMemoTime: this.chapter.planMemoTime,
      showSelectStrength: false,
      showSelectStudyTime: false,
      showSelectGoal: false,
      showMemoScene: false,
      isShowSetTime: false,
      isShowSetDate: false,
      isShowSetNum: false,
      title1: '选择记忆场景',
      step: 1,
      planNewCardNum1: 0,
      planMemoTime1: 30,
    }
  },
  methods: {
    onSetMemoTime() {
      if (!this.isFirst) {
        if (this.planMemoTime.length == 0) {
          this.$confirm('<div style="color:#202020;font-weight:600;font-size:16px">是否切换至【按每天投入时间】进行安排？</div> \n切换后原设置无效，将会按新的学习计划进行安排', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            dangerouslyUseHTMLString: true,
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                this.showSelectStudyTime = true
                this.isShowSetTime = true
                done();
              } else {
                this.isShowSetTime = false
                done();
              }
            }
          })
        } else {
          if (this.planMemoScene === 1) {
            this.isShowSetTime = true
          }
          this.showSelectStudyTime = true
        }
      }
    },
    setPlanNewCardNum(num) {
      this.planNewCardNum1 = num
    },
    setPlanMemoTime(time) {
      this.planMemoTime1 = time
    },
    onPrevStepClick() {
      this.step = 1
      this.title1 = '选择记忆场景'
    },
    onNextStepClick() {
      this.isShowSetTime = false
      if (this.planMemoScene == 1) {
        this.title1 = '设置每天学习量'
      } else {
        this.title1 = '选择考试日期'
      }
      if (this.step === 1) {
        this.step = 2
      } else {
        if (this.planMemoScene == 2) {
          this.planNewCardNum1 = 0
        }
        request.request({
          url: '/chapter/savePlanByScene',
          method: "post",
          data: {
            chapterId: this.chapter.chapterId,
            planMemoScene: this.planMemoScene,
            planMemoObj: this.chapter.planMemoObj,
            planMemoTime: this.planMemoTime1,
            planNewCardNum: this.planNewCardNum1
          }
        }).then(async res => {
          await this.$parent.$parent.getChapterInfo()
          this.showMemoScene = false
          this.step = 1
        }).catch(e => {
          showMessage({ message: e.message, type: 'error' })
        })
      }
    },
    selectScene(scene) {
      this.planMemoScene = scene
    },
    setPlanCardNum(num) {
      this.planNewCardNum = num
      this.setPlanByScene(0, num)
    },
    setPlanByScene(time, cardNum) {
      request.request({
        url: '/chapter/savePlanByScene',
        method: 'post',
        data: {
          chapterId: this.chapter.chapterId,
          planMemoTime: time,
          planMemoScene: this.planMemoScene,
          planMemoObj: this.chapter.planMemoObj,
          planNewCardNum: cardNum
        }
      }).then(res => {
        this.$parent.$parent.getChapterInfo()
        this.planMemoTime = time
        this.planNewCardNum = res.planNewCardNum
        this.planRevCardNum = res.planRevCardNum
        this.lrnLevel = res.planLrnLevel
        this.showSelectStudyTime = false
      }).catch(e => {
        showMessage({ message: e.message, type: 'error' })
      })
    },
    // 设置每日学时
    SetStudyTime(time) {
      this.setPlanByScene(time, 0)
    },
    // 设置每日学习新卡数量
    SetStudyNum(num) {
      this.setPlanByScene(0, num)
    },
    selectGoal(id) {
      this.planMemoObj = id
      request.request({
        url: '/chapter/savePlanByScene',
        method: "post",
        data: {
          chapterId: this.chapter.chapterId,
          planMemoObj: this.planMemoObj,
        }
      }).then(res => {
        this.lrnLevel = res.planLrnLevel
        this.showSelectGoal = false
      })
    },
    selectStrength(id) {
      this.lrnLevel = id
      this.showSelectStrength = false
    },
    finishSettingPlan() {
      request.request({
        url: '/chapter/savePlan',
        method: 'post',
        data: {
          chapterId: this.chapter.chapterId,
          lrnLevel: this.lrnLevel,
          planNewCardNum: this.planNewCardNum,
          planRevCardNum: this.planRevCardNum,
          planNewOrder: this.planNewOrder,
          planStudyOrder: this.planStudyOrder,
        }
      }).then(res => {
        showMessage({ message: '保存成功!', type: 'success' })
        this.$emit('initSuccess', res.planId)
      }).catch(e => {
        showMessage({ message: e.message, type: 'error' })
      })
    },
  },
  computed: {
    studyGoal() {
      return (planMemoObj) => {
        if (planMemoObj === 1) {
          return '简单了解'
        } else if (planMemoObj === 2) {
          return '初步掌握'
        } else {
          return "熟练运用"
        }
      }
    },
    memoStrength() {
      return (id) => {
        switch (id) {
          case 1:
            return '低'
          case 2:
            return '中'
          case 3:
            return '高'
          case 4:
            return '强'
        }
      }
    }
  },
  watch: {
    chapter(n) {
      this.planMemoScene = n.planMemoScene
      this.lrnLevel = n.planLrnLevel
      this.planNewCardNum = n.planNewCardNum
      this.planRevCardNum = n.planRevCardNum
      this.planMemoObj = n.planMemoObj
      this.planMemoTime = n.planMemoTime
    }
  },
}
</script>
<style lang="scss" scoped>
.study-preference {
  .set-item {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(32, 32, 32, 0.06);

    .set-option {
      font-size: 16px;
    }

    .set-value {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: rgba(32, 32, 32, 0.4);

      &:hover {
        cursor: pointer;
      }

      .el-input {
        width: 44px;
        margin-right: 6px;

        ::v-deep .el-input__inner {
          height: 24px !important;
          line-height: 24px;
          text-align: center;
          padding: 0;
        }
      }

      .el-radio {
        color: rgba(32, 32, 32, 0.4);
      }
    }
  }

  .study-set-item {
    padding: 18px 0;
    border-bottom: 1px solid rgba(32, 32, 32, 0.06);

    .set-option {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .set-value {
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      .el-radio {
        color: rgba(32, 32, 32, 0.4);
      }
    }
  }
}

.el-radio-group {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
}

::v-deep .el-dialog {
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
  background-color: #f7f7f7;
}

::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

::v-deep .el-dialog__body {
  padding: 12px 24px 32px 24px;
}

.next-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  ::v-deep .el-button {
    width: 112px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>