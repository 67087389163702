<template>
  <div class="plan-num">
    <div class="studyTime">
      <div class="text">每日学习新卡数 (张)</div>
        <el-select v-model="planNewCardNum" filterable allow-create placeholder="选择学习新卡数">
          <el-option v-for="item in cardList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
    </div>
    <div class="plan-tips">
      Tips: 按每天学习新卡数，自主安排每天学习卡片数量
    </div>
    <div class="display-flex justify-content-center mg-t-20">
      <el-button type="primary" class="set-btn" @click="onSetClick">设置</el-button>
    </div>
  </div>
</template>
<script>
import { showMessage } from '../../libs/message';

export default {
  props:{
    chapter:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      planNewCardNum: this.chapter.planNewCardNum,
      cardList: [
        {
          value: '10',
          label: '10'
        }, {
          value: '20',
          label: '20'
        },
        {
          value: '30',
          label: '30'
        },
        {
          value: '50',
          label: '50'
        },
        {
          value: '60',
          label: '60'
        },
        {
          value: '80',
          label: '80'
        },
        {
          value: '100',
          label: '100'
        },
        {
          value: '150',
          label: '150'
        },
        {
          value: '200',
          label: '200'
        },
        {
          value: '300',
          label: '300'
        }
      ]
    }
  },
  methods: {
    onSetClick(){
      if(Number(this.planNewCardNum)){
        if(Number(this.planNewCardNum)>=0) {
          this.$emit('SetStudyNum',this.planNewCardNum)
        }else {
          showMessage({message:'新卡数量必须大于0',type:'warning'})
        }
      }else {
        showMessage({message:'请输入数字',type:'warning'})
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.plan-num {
  .studyTime {
    margin-top: 20px;
    width: 100%;

    .text {
      font-size: 14px;
      margin: 10px 0;
    }

    .el-select {
      width: 100%;
    }
  }

  .plan-tips {
    text-align: center;
    font-size: 12px;
    color: rgba(0, 0, 10, 0.4);
    line-height: 17px;
    margin-top: 12px;
  }

  .set-btn {
    width: 80%;
    border-radius: 8px;
  }
}

::v-deep .el-input__inner {
  border-radius: 8px;
}
</style>