<template>
  <div class="plan-num">
    <div class="studyTime">
      <div class="text">每日学时 (分)</div>
      <el-select v-model="planMemoTime" filterable allow-create placeholder="选择学习时长">
        <el-option v-for="item in timeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
    </div>
    <div class="plan-tips">
      Tips: 按每天投入的时间，自动安排每天学习卡片数量
    </div>
    <div class="display-flex justify-content-center mg-t-20">
      <el-button type="primary" class="set-btn" @click="onSetClick">设置</el-button>
    </div>
  </div>
</template>
<script>
import { showMessage } from '../../libs/message';

export default {
  props:{
    chapter:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      planMemoTime: this.chapter.planMemoTime,
      timeList: [{
        value: '5',
        label: '5'
      }, {
        value: '10',
        label: '10'
      }, {
        value: '20',
        label: '20'
      },
      {
        value: '30',
        label: '30'
      },
      {
        value: '60',
        label: '60'
      },
      {
        value: '90',
        label: '90'
      },
      {
        value: '120',
        label: '120'
      },
      {
        value: '150',
        label: '150'
      },
      {
        value: '180',
        label: '180'
      },
      {
        value: '210',
        label: '210'
      }
      ],
    }
  },
  methods: {
    onSetClick(){
      if(Number(this.planMemoTime)){
        if(Number(this.planMemoTime)>=0) {
          this.$emit('SetStudyTime',this.planMemoTime)
        }else {
          showMessage({message:'学习时间必须大于0',type:'warning'})
        }
      }else {
        showMessage({message:'请输入数字',type:'warning'})
      }
    }
  },
  watch:{
    chapter(n){
      this.planMemoTime =  n.planMemoTime
    }
  },
  mounted() {
    console.log(this.chapter);
  },
}
</script>
<style lang="scss" scoped>
.plan-num {
  .studyTime {
    margin-top: 20px;
    width: 100%;

    .text {
      font-size: 14px;
      margin: 10px 0;
    }

    .el-select {
      width: 100%;
    }
  }

  .plan-tips {
    text-align: center;
    font-size: 12px;
    color: rgba(0, 0, 10, 0.4);
    line-height: 17px;
    margin-top: 12px;
  }

  .set-btn {
    width: 80%;
    border-radius: 8px;
  }
}

::v-deep .el-input__inner {
  border-radius: 8px;
}
</style>