<template>
  <!-- 设置学习目标 -->
  <div class="plan-degree">
    <div class="plan-item" v-for="(item, index) in degreeList" :key="item.id" @click="sceneClick(item, index)"
      :class="planMemoScene === item.id || currentIndex === index ? 'active' : ''">
      <div class="content">
        <div class="title">{{ item.title }}</div>
        <div class="desc">{{ item.desc }}</div>
      </div>
      <img src="../../assets/images/icons_filled_toggle_PC_light_tick-circle_24pt@2x.png" class="degree-img"
        v-if="planMemoScene === item.id || currentIndex === index" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    planMemoScene: {
      type: Number
    }
  },
  data() {
    return {
      currentIndex: '',
      degreeList: [
        {
          title: '长期学习',
          desc: '持续安排复习，没有截止时间',
          id: 1
        },
        {
          title: '准备考试',
          desc: '有明确的截止时间，到期后不再复习',
          id: 2
        }
      ],
    }
  },
  methods: {
    sceneClick(item, index) {
      this.currentIndex = index
      this.$emit('selectScene', item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";

.plan-desc {
  margin-bottom: 20px;
  color: rgba(32, 32, 32, 0.6);
}

.plan-degree {
  .plan-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid rgba(32, 32, 32, 0.16);
    margin-bottom: 16px;

    &.active {
      border: 2px solid #FFA800;
      ;
    }

    &:hover {
      cursor: pointer;
    }

    .content {
      display: flex;
      align-items: center;

      .title {
        font-size: 14px;
        margin-right: 8px;
        border-radius: 6px;
        color: #202020;
      }

      .desc {
        font-size: 12px;
        color: rgba(32, 32, 32, 0.4);
      }
    }

    .degree-img {
      width: 20px;
      height: 20px;
    }
  }
}
</style>