<template>
  <el-dialog title="返回上一级" :visible.sync="showPublish" width="744px" :close-on-click-modal="false" top="15vh"
  >
    <span slot="title" class="my-dialog-title" @click="onBackStep"><i class="el-icon-arrow-left"></i>返回上一级</span>
    <div class="publish-box">
      <div class="publish-content">
        <div class="top">
          <div class="chapter-name">{{ chapter.name }}</div>
          <el-button type="primary" class="w-120" @click="onPublishClick">{{ btnText }}</el-button>
        </div>
        <div class="title mg-t-20">学习阶段</div>
        <el-select v-model="submitData.level" placeholder="请选择" popper-class="myselect1">
          <el-option v-for="item in levelList" :key="item.level" :label="item.name" :value="item.level">
          </el-option>
        </el-select>
        <div class="title mg-t-30">学科专业</div>
        <el-select v-model="submitData.cate" multiple filterable remote allow-create placeholder="请选择或输入 最多3个"
                   :multiple-limit="3" :remote-method="getRemoteTag" :loading="loading" popper-class="myselect1">
          <el-option v-for="item in cateList" :label="item" :value="item" :key="item">
          </el-option>
        </el-select>
        <div class="title mg-t-30">选择是否付费</div>
        <div class="pay">
          <div class="pay-switch" :style="{ borderBottom: checked ? '1px solid #eaeaea' : '' }">
            <div class="pay-name">付费开关</div>
            <el-switch v-model="checked" inactive-color="#A6A6A6" active-color="#FFB700"></el-switch>
          </div>
          <div class="pay-switch" style="height: 64px" v-if="checked">
            <div class="pay-name">商品价格</div>
            <div class="pay-money">
              <div class="money-icon">¥</div>
              <el-input v-model="submitData.price" type="number"></el-input>
            </div>
          </div>
          <!-- <div class="pay-switch" style="height: 64px" v-if="checked">
          <div class="pay-name">试学数量</div>
          <el-switch v-model="checked" inactive-color="#A6A6A6" active-color="#FFB700"></el-switch>
        </div> -->
        </div>
        <template v-if="false">
          <div class="title mg-t-36">选择是否拼团</div>
          <div class="pay">
            <div class="pay-switch" :style="{ borderBottom: submitData.groupEnable == 1 ? '1px solid #eaeaea' : '' }">
              <div class="pay-name">拼团开关</div>
              <el-switch v-model="submitData.groupEnable" inactive-color="#A6A6A6" active-color="#FFB700"
                         active-value="1" inactive-value="0"></el-switch>
            </div>
            <div class="pay-switch" style="height: 64px" v-if="submitData.groupEnable == 1">
              <div class="pay-name">拼团价格</div>
              <div class="pay-money">
                <div class="money-icon">¥</div>
                <el-input v-model="submitData.groupPrice" type="number"></el-input>
              </div>
            </div>
            <div class="pay-switch" style="height: 64px" v-if="submitData.groupEnable == 1">
              <div class="pay-name">拼团时间</div>
              <div class="pay-money">
                <div class="money-icon">小时</div>
                <el-input v-model="submitData.groupTime" type="number" style="width:auto"></el-input>
              </div>
            </div>
            <div class="pay-switch" style="height: 64px" v-if="submitData.groupEnable == 1">
              <div class="pay-name">拼团人数</div>
              <div class="pay-money">
                <el-input v-model="submitData.groupUser" type="number"></el-input>
              </div>
            </div>
          </div>
        </template>
        <div class="title mg-t-30">
          <div class="content mg-b-20">
            <div class="left">商品标题</div>
            <div class="right">{{ titleLength }}/35</div>
          </div>
          <el-input autosize placeholder="给商品添加一个响亮的标题" v-model="submitData.title" maxlength="35"
                    @input="handleTitleInput" class="title-input">
          </el-input>
        </div>
        <div class="title mg-t-30">商品描述</div>
        <div class="bg-f4f bdr-10 pd-t-15 pd-b-15">
          <publishEditor :commodityDesc="submitData.introduction" @editorChange="editorChange" ref="myEditor">
          </publishEditor>
        </div>
        <div class="justify-content-center mg-t-15">
          <el-button type="primary" class="w-120" @click="onPublishClick">{{ btnText }}</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import publishEditor from './PublishEditor.vue'
import request from '../../libs/api.request'
import {showMessage} from '../../libs/message'

export default {
  props: {
    chapter: {
      type: Object,
      default: () => {
      }
    },
    publishChapterDetailInfo: {
      default: () => {
      }
    }
  },
  components: {
    publishEditor
  },
  data() {
    return {
      btnText: '',
      showPublish: false,
      loading: false,
      showLastStep: true,
      btnText: '',
      levelList: [
        {name: '英语', level: 1},
        {name: '外语', level: 5},
        {name: '考研', level: 2},
        {name: '考证职考', level: 3},
        {name: '专升本', level: 4},
        {name: '大学', level: 6},
        {name: '高中', level: 7},
        {name: '初中', level: 8},
        {name: '小学', level: 9},
        {name: '学前', level: 10},
        {name: '其他', level: 11},
      ],
      submitData: {
        price: 1,
        cate: '',
        level: '',
        introduction: '',
        title: '',
        allowEdit: '1',
        // groupEnable: '0',
        // groupUser: 2,
        // groupPrice: 0.01,
        // groupTime: 24
      },
      cateList: [],
      checked: false,
      groupEnableCheck: false,
      groupEnable: false,
      titleLength: 0
    }
  },
  methods: {
    // 发布或更新商品
    onPublishClick() {
      if (this.submitData.level === '') return showMessage({message: '请选择学习阶段', type: 'error'})
      if (this.submitData.cate.length === 0) return showMessage({message: '至少选择一项学科专业分类', type: 'error'})
      if (this.checked && this.submitData.price <= 0) return showMessage({message: '付费金额必须大于0', type: 'error'})
      // if (this.submitData.groupEnable == 1 && this.submitData.groupPrice <= 0) return showMessage({ message: '拼团价格必须大于0', type: 'error' })
      // if (this.submitData.groupEnable == 1 && this.submitData.groupPrice >= this.submitData.price) return showMessage({ message: '拼团价格需小于商品价格', type: 'error' })
      // if (this.submitData.groupEnable == 1 && this.submitData.groupUser <= 1) return showMessage({ message: '拼团人数至少2人', type: 'error' })
      // if (this.submitData.groupEnable == 1 && this.submitData.groupTime <= 0) return showMessage({ message: '拼团时间必须大于0', type: 'error' })
      if (this.submitData.title === '') return showMessage({message: '请为商品添加标题', type: 'error'})
      this.submitData.cate = JSON.stringify(this.submitData.cate)
      let msg = '', Api = ''
      msg = this.btnText
      Api = this.publishChapterDetailInfo === null ? '/shop/chapter/publish' : '/shop/chapter/update'
      let loading = this.$loading({text: `商品${msg}中...`})
      if (!this.checked) {
        this.submitData.price = 0
      }
      setTimeout(() => {
        request.request({
          url: Api,
          data: {
            chapterId: this.$route.params.chapterId,
            ...this.submitData
          },
          method: 'post'
        }).then(res => {
          loading.close()
          showMessage({message: `${msg}成功`, type: "success"})
          this.$parent.getChapterInfo()
          this.$parent.showPublish = false
          this.showPublish = false
        }).catch(e => {
          loading.close()
          this.submitData.cate = JSON.parse(this.submitData.cate)
          showMessage({message: e.message, type: "error"})
        })
      }, 200)
    },
    initProductDetail() {
      if (this.publishChapterDetailInfo !== null && this.publishChapterDetailInfo !== undefined) {
        console.log();
        this.btnText = '保存'
        this.submitData.introduction = this.publishChapterDetailInfo.introduction
        let res = this.levelList.filter(item => {
          return item.level === this.publishChapterDetailInfo.level
        })
        this.firstCategoryName = res.length > 0 ? res[0].name : ''
        this.submitData.cate = this.publishChapterDetailInfo.cate
        this.submitData.level = this.publishChapterDetailInfo.level
        this.submitData.allowEdit = this.publishChapterDetailInfo.allowEdit + ''
        this.submitData.title = this.publishChapterDetailInfo.title
        this.titleLength = this.publishChapterDetailInfo.title.length
        this.submitData.price = this.publishChapterDetailInfo.price / 100
        if (this.publishChapterDetailInfo.price > 0) {
          this.checked = true
        } else {
          this.checked = false
        }
      } else {
        this.btnText = '发布'
      }
    },
    editorChange(desc) {
      this.submitData.introduction = desc
    },
    getRemoteTag(keyword) {
      if (keyword !== '') {
        this.loading = true;
        request.request({
          url: '/shop/index/searchCate',
          params: {
            keyword: keyword
          }
        }).then(data => {
          this.loading = false;
          this.cateList = data.list;
        }).catch(data => {
          this.loading = false;
          this.cateList = [];
        });
      }
    },
    handleTitleInput() {
      this.titleLength = this.submitData.title.length
    },
    open() {
      this.showPublish = true
    },
    onBackStep() {
      this.showPublish = false
    }
  },
  watch: {
    publishChapterDetailInfo(v) {
      console.log(v);
      this.initProductDetail()
    }
  },
  mounted() {
    this.initProductDetail()
  }
}
</script>

<style lang="scss" scoped>
.my-dialog-title {
  color: #202020;
  font-size: 16px;

  &:hover {
    cursor: pointer;
  }
}

::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

::v-deep .el-select .el-tag {
  margin-left: 16px;
  font-size: 14px;
}

.publish-box {
  margin-bottom: 40px;

  .publish-content {

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .chapter-name {
        font-size: 20px;
        font-weight: 600;
        color: #202020;
      }

      .chapter-publish-btn {
        width: 200px;
        height: 48px;
        background: #FFB700;
        border-radius: 8px;
        color: #fff;
      }
    }

    .title {
      font-size: 16px;
      font-weight: 500;
      color: #202020;
      line-height: 14px;
      margin-bottom: 12px;

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          font-weight: 500;
          color: #202020;
          font-size: 20px;
        }

        .right {
          font-size: 16px;
          font-weight: 500;
          color: #202020;
          opacity: .4;
        }
      }

      ::v-deep .title-input .el-input__inner {
        font-size: 18px;

        &::placeholder {
          opacity: .6;
        }
      }
    }

    .allow-editor {
      display: flex;
      background-color: #fff;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      border-radius: 12px;
    }

    .pay {
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      background-color: #fff;
      padding: 0 16px;

      .pay-switch {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        color: #202020;

        .pay-name {
          font-size: 17px;
        }

        .pay-money {
          display: flex;
          align-items: center;

          ::v-deep .el-input__inner {
            width: 120px !important;
            height: 40px !important;
            line-height: 40px;
            padding: 0;
            text-align: center;
            border-radius: 10px;
          }

          .money-icon {
            font-size: 17px;
            color: #202020;
            margin-right: 17px;
          }
        }
      }
    }
  }
}

::v-deep .el-select {
  width: 100%;
}

::v-deep .el-input__inner {
  height: 62px;
  line-height: 62px;
  border-radius: 12px;
  font-size: 16px;
  padding: 16px;
  color: #202020;

  &::placeholder {
    color: #202020;
  }
}

::v-deep .el-input__suffix {
  right: 22px;
}

::v-deep .el-select .el-input .el-select__caret {
  font-size: 18px !important;
}

::v-deep .el-select__input {
  margin-left: 16px;
}

::v-deep .el-dialog {
  top: 70px !important;
  transform: translate(-50%, 0) !important;
}
</style>

<style>
.myselect1 {
  width: 428px !important;
  border-radius: 12px;
  min-width: 0 !important;
}

.myselect1 .el-select-dropdown__item {
  width: 428px;
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  font-size: 16px;
  color: #202020;
}

.myselect1 .el-select-dropdown__item:hover {
  background: #EBEBEB;
  opacity: .8;
}

.myselect .el-scrollbar__bar {
  width: 0 !important;
}
</style>
